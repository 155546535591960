import React, {Component} from 'react';
import styled, {keyframes} from 'styled-components';

import Logo from '../../molecules/logo';
import DropDown from '../../molecules/langDropDownMenu';
import Text from '../../molecules/plainText';

import * as errorTypes from '../../../logic/order/errorTypes';
import {connect} from 'react-redux';
import {mapStateToProps} from '../header/container';
import PropTypes from 'prop-types';
import {CRYPTO_CURRENCIES, EMPTY_CURRENCY, NOT_CRYPTO_CURRENCIES} from "../../../environment/supported_currencies";

const SECOND = 1000;

const StyledWrapper = styled.div`
  padding: ${props => props.template === 'fc' ? "19px 7vw" : "2vh 7vw"};
  @media screen and (max-width: 1023px) and (orientation: landscape) {
    padding: 2vw 10vh;
  }
  display: flex;
  justify-content: ${props => props.template === 'fc' ? "space-around" : "space-between"};
  align-items: center;
  background: ${props => props.template === 'fc' ? "#3F3456" : "white"};
`;

const openMessage = keyframes`
    from {
            opacity: 0;
            transform: translateY(-50px)
        }
        to {
            opacity: 1;
            transform: translateY(0px)
        }
`;

const closeMessage = keyframes`
    from {
            opacity: 1;
            transform: translateY(0px)
            
        }
        to {
            opacity: 0;
            transform: translateY(-50px)
        }
`;

const StyledMessageWrapper = styled.div`
  width: 98%;
  background-color: ${props => props.theme.colors.bg.octonary};
  border-radius: 0.5rem;
  padding: 0.5rem 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 1%;
  right: 1%;
  z-index: 111;
  animation: 1s ease-out 0s;
  animation-name: ${props => (props.internet ? closeMessage : openMessage)};
`;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      internet: true,
      messageOpen: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {
      orderError,
      orderErrorInfo,
      coinsError,
      coinsErrorInfo,
      serviceMessageError,
      serviceMessageErrorInfo,
    } = this.props;
    if (
      prevProps.orderError !== this.props.orderError ||
      prevProps.coinsError !== this.props.coinsError ||
      prevProps.orderErrorInfo !==this.props.orderErrorInfo ||
      prevProps.coinsErrorInfo !== this.props.coinsErrorInfo ||
      prevProps.serviceMessageError !== this.props.serviceMessageError ||
      prevProps.serviceMessageErrorInfo !== this.props.serviceMessageErrorInfo
    ) {
      if (
        (orderError === true && orderErrorInfo.type === errorTypes.NETWORK_ERROR) ||
        (coinsError === true && coinsErrorInfo.type === errorTypes.NETWORK_ERROR) ||
        (serviceMessageError === true &&
          serviceMessageErrorInfo.type === errorTypes.NETWORK_ERROR)
      ) {
        this.setState({internet: false, messageOpen: true});
      } else {
        this.setState({internet: true});
        setTimeout(() => this.setState({messageOpen: false}), SECOND);
      }
    }
  }
  render() {
    const {messageOpen, internet} = this.state;
    const {order} = this.props;
    return (
      <StyledWrapper template={this.props.template}>
        {messageOpen && order.status === 'pending' && (order.currency === EMPTY_CURRENCY || NOT_CRYPTO_CURRENCIES.includes(order.currency)) && (
          <StyledMessageWrapper internet={internet}>
            <Text>No internet connection...</Text>
          </StyledMessageWrapper>
        )}
        <Logo template={this.props.template} />
        {/*Should be uncommented when translation options will be available*/}
        {/*<DropDown />*/}
      </StyledWrapper>
    );
  }
}

export default connect(mapStateToProps)(Header);

Header.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    cost: PropTypes.number.isRequired,
    amount: PropTypes.number.isRequired,
    currency: PropTypes.oneOf([EMPTY_CURRENCY, ...NOT_CRYPTO_CURRENCIES, ...CRYPTO_CURRENCIES]).isRequired,
    email: PropTypes.string.isRequired,
    user_id: PropTypes.string.isRequired,
    order_id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    product_name: PropTypes.string.isRequired,
    site_name: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    expired_at: PropTypes.string.isRequired,
    url_return: PropTypes.string.isRequired,
  }).isRequired,
  orderError: PropTypes.bool.isRequired,
  orderErrorInfo: PropTypes.shape({
    type: PropTypes.string,
    prevAction: PropTypes.shape({
      type: PropTypes.string,
      payload: PropTypes.any,
    }),
    response: PropTypes.any,
  }).isRequired,
};
