import React, {Component} from 'react';
import styled from 'styled-components';

import Text from '../../atoms/text';
import {ALink} from '../../atoms/link';
import {ARROW} from "../../atoms/svg";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default class NavigationBar extends Component {
  render() {
    return (
      <StyledWrapper>
          <ARROW side="left"/>
        <Text bold="true" blue="true" fontSize="0.88rem">
          <ALink href="/">Back</ALink>
        </Text>
      </StyledWrapper>
    );
  }
}
