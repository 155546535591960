import React, {Component, Fragment} from 'react';


import {FC_LOGO, LOGO} from "../../atoms/svg";

export default class Logo extends Component {
  render() {
    let logo;
    if (this.props.template === 'fc'){
      logo = <FC_LOGO/>
    } else{
      logo = <LOGO/>
    }
    return (
      <Fragment>
        {logo}
      </Fragment>
    );
  }
}
