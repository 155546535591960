import React, {Component} from 'react';

import Text from '../../atoms/text';

import PropTypes from 'prop-types';

export default class ConfirmationText extends Component {
  render() {
    const {children} = this.props;
    return (
      <Text template={this.props.template} green="true" bold="true" fontSize="1.12rem">
        {children}
      </Text>
    );
  }
}

ConfirmationText.propTypes = {
  children: PropTypes.string,
};
