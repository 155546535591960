import {EMPTY_CURRENCY, CRYPTO_CURRENCIES, NOT_CRYPTO_CURRENCIES} from "../environment/supported_currencies";
const MANDATORY_ORDER_FIELDS = ['amount', 'cost', 'created_at', 'currency', 'email', 'expired_at', 'id', 'product_name', 'site_name', 'status', 'url_return'];

function stringIsEmpty (string) {
  return string.length === 0 || !string.trim();
};

function validateEmail(email) {
  let re = /\S+@\S+\.\S+/;
  return re.test(email);
}

function checkCoinsArray(coins) {
  for (const [key] of Object.entries(coins)){
    if (!CRYPTO_CURRENCIES.includes(key)) {
      return false;
    }
  }
  return true;
}

function checkOrder(order) {
  if (order) {
    for (const [key, value] of Object.entries(order)) {
      if ((key === 'currency') && !(value === EMPTY_CURRENCY || NOT_CRYPTO_CURRENCIES.includes(value) || CRYPTO_CURRENCIES.includes(value))){
        return false;
      }
      if ((key !== 'currency') && (MANDATORY_ORDER_FIELDS.includes(key) && (typeof value === 'undefined' || value === null))){
        return false;
      }
    }
  } else return false;
  return true;
}

export default function responseBodyHandling(body, action) {
  if (body && body.data && body.data.data) {
    switch (action) {
      case 'LOAD_COINS': {
        return checkCoinsArray(body.data.data);
      }
      case 'FIRST_LOAD_ORDER':
      case 'SEND_CURRENCY':
      case 'SEND_STATUS':
      case 'LOAD_ORDER': {
        return checkOrder(body.data.data);
      }
      default: {
        break;
      }
    }
  } else {
    return false;
  }
}
