import {put, all, takeLatest,  delay} from 'redux-saga/effects';
import {call} from 'redux-saga/effects';
import api from '../../services/api';
import * as actionTypes from './actionTypes';
import {innitServiceAjaxSaga} from './actions';
import * as errorTypes from './errorTypes';

const SECOND = 1000;
const MAX_CALLS_TRIES = 3;

function* sendErrorMessage(action) {
  let callsCounter = 0;
  let prevError = false;
  while (callsCounter <= MAX_CALLS_TRIES) {
    try {
      let response = yield call(api.sendRequest, `/collect`, 'post', action.payload);
      yield put({
        type: actionTypes.SEND_ERROR_MESSAGE_SUCCESS,
      });
      break;
    } catch (error) {
      if(prevError !== false && error !== undefined){
        if (prevError !== error.statusText ) {
          callsCounter = 0;
        }
      }
      prevError = error ? error.statusText : undefined;
      if (callsCounter < MAX_CALLS_TRIES) {
        yield delay(5 * SECOND);
      } else {
        if (!error) {
          yield put({
            type: actionTypes.SEND_ERROR_MESSAGE_ERROR,
            payload: {
              error: true,
              errorInfo: {type: errorTypes.NETWORK_ERROR, prevAction: action},
            },
          });
        }
      }
      callsCounter++;
    }
  }
}

export default function* actionsListener() {
  yield put(innitServiceAjaxSaga());
  yield all([takeLatest(actionTypes.SEND_ERROR_MESSAGE, sendErrorMessage)]);
}
