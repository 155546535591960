import React, {Component, Fragment} from 'react';
import styled from 'styled-components';

import PlainButton from '../../molecules/plainButtton';

import {connect} from 'react-redux';
import {mapStateToProps} from './container';
import PropTypes from 'prop-types';
import {CRYPTO_CURRENCIES, EMPTY_CURRENCY, NOT_CRYPTO_CURRENCIES} from "../../../environment/supported_currencies";

const ButtonWrapper = styled.div`
  min-height: 1rem;
`;

const PlainButtonWrapper = styled.div`
  margin-bottom: 3rem !important;
`

class ModalFooter extends Component {
  render() {
    const {order, template} = this.props;
    return (
      <Fragment>
        {order.status === 'successful' && (
          <ButtonWrapper>
            <PlainButtonWrapper>
              <PlainButton template={template} href={order.url_return} notFilled_withLink_samePage={true}>
                Ok
              </PlainButton>
            </PlainButtonWrapper>
          </ButtonWrapper>
        )}
      </Fragment>
    );
  }
}

export default connect(mapStateToProps)(ModalFooter);

ModalFooter.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    cost: PropTypes.number.isRequired,
    amount: PropTypes.number.isRequired,
    currency: PropTypes.oneOf([EMPTY_CURRENCY, ...NOT_CRYPTO_CURRENCIES, ...CRYPTO_CURRENCIES]).isRequired,
    email: PropTypes.string.isRequired,
    user_id: PropTypes.string.isRequired,
    order_id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    product_name: PropTypes.string.isRequired,
    site_name: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    expired_at: PropTypes.string.isRequired,
    url_return: PropTypes.string.isRequired,
  }).isRequired,
};
