import React, {Component} from 'react';
import styled from 'styled-components';

import {Dropdown, Icon} from 'semantic-ui-react';


const StyledIcon = styled(Icon)`
  color: ${props => props.theme.colors.text.primary};
  margin-right:0.5rem !important;
`;

const StyledDropdown = styled(Dropdown)`
  &&&& {
    .dropdown: before {
      margin-left: 1.3vw;
      color: ${props => props.theme.colors.text.primary};
    }
    .menu{
      @media (max-width: 767px) { 
        left: -15px;
       }
     }
    .icon {
      transform: ${props =>
        props.rotate === "true" ? 'rotate(-180deg) translateX(-1.3vw)' : 'rotate(0deg)'};
    }
    .text {
      color: ${props => props.theme.colors.text.primary};
      font-size: ${props => props.theme.fonts.sizes.sm};
      font-weight: ${props => props.theme.fonts.weights.semibold};
    }
  }
`;

export default class LangDropDownMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rotate: "false",
      data: [
        {
          key: 'English',
          text: 'English',
          value: 'English',
        },
        {
          key: 'Ukrainian',
          text: 'Ukrainian',
          value: 'Ukrainian',
        },
        {
          key: 'Russian',
          text: 'Russian',
          value: 'Russian',
        },
      ],
    };
  }

  render() {
    const {data} = this.state;
    return (
      <div>
        <StyledIcon name="globe" size="large" />
        <StyledDropdown
          inline
          options={data}
          defaultValue={data[0].value}
          rotate={this.state.rotate.toString()}
          onClick={() => {
            this.setState({rotate: this.state.rotate.toString() === "true" ? "false" : "true"});
          }}
          closeOnEscape={true}
        />
      </div>
    );
  }
}

// LangDropDownMenu.propTypes = {
//   langs: PropTypes.arrayOf(PropTypes.shape({
//     key: PropTypes.string,
//     text: PropTypes.string,
//     value: PropTypes.string
//   }))
// }
