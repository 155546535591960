import React from 'react';
import Page from './components/pages/index';
import GlobalStyles from './global_styles/index';
import {Router} from 'react-router-dom';
import history from "./history";


function App() {
    return (
        <Router history={history}>
            <GlobalStyles/>
            <Page/>
        </Router>
    );
}

export default App;
