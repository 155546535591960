import defaultTheme from './default/theme'
/* import [THEME_COMPONENT_NAME] from '[PATH]' */

const index = {
    default: defaultTheme,
    /*[THEME_NAME] : [THEME_COMPONENT_NAME],*/
};

export default function getTheme(themeName){
    return index.hasOwnProperty(themeName) ? index[themeName] : index['default'];
}