import React, {Component} from 'react';

import 'semantic-ui-css/semantic.min.css';
import TitleText from '../../molecules/titleText';

class SupportPage extends Component {
  componentDidMount() {
    document.title = "CoinCentro|support";
  }
  render() {
    return <TitleText>Support</TitleText>;
  }
}

export default SupportPage;
