import React, {Component, Fragment} from 'react';
import styled, {css, keyframes} from 'styled-components'

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }

`;

const animation = css`
  ${rotate360} infinite 2s linear;
`;

const Wrapper = styled.div`
  .logoSVG {
    min-height: 1rem;
    display: block;
  }

  .logoFC {
    height: 18px;
    display: block;
  }

  .arrowSVG_left {
    margin-right: 1em;
    position: relative;
    top: 0.5px;
    height: 1.5em;
  }

  .arrowSVG_right {
    height: 1.5em;
    margin-left: 1em;
    position: relative;
    top: 0.5px;
    transform: rotate(0.5turn);
  }

  .successSVG_big {
    padding: 1.5vh 0 0.5vh;
    @media screen and (max-width: 1023px) and (orientation: landscape) {
      padding: 1.5vw 0 0.5vw;
    }
    z-index: 0;
    height: 11em;
  }

  .successSVG_small {
    padding: 1.5vh 0 1vh;
    @media screen and (max-width: 1023px) and (orientation: landscape) {
      padding: 1.5vw 0 1vw;
    }
    z-index: 0;
    height: 10em;

  }

  .spinnerSVG {
    z-index: 1;
    height: 4em;
    animation: ${animation};
  }
`;


export class ARROW extends Component {
    render() {
        const {side} = this.props;
        return (
            <Wrapper>
                <svg className={"arrowSVG_" + side} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path
                        d="M13,17a.908.908,0,0,1-.7-.3l-6-6a.967.967,0,0,1,0-1.4l6-6a.99.99,0,0,1,1.4,1.4L8.4,10l5.3,5.3a.967.967,0,0,1,0,1.4A.908.908,0,0,1,13,17Z"
                        fill="#008F8C"/>
                </svg>
            </Wrapper>
        )
    }
}

export class LOGO extends Component {
    render() {
        return (
            <Wrapper>
                <svg className="logoSVG" width="151" height="27" viewBox="0 0 1920 336" fill="#05264F" xmlns="http://www.w3.org/2000/svg">
                        <path d="M698.293 116.247H698.36C685.645 109.245 671.318 105.694 656.804 105.947C640.769 105.666 624.889 109.116 610.416 116.025C606.833 117.792 603.384 119.815 600.094 122.08V110.314H513.905V326.102H600.094V205.113C599.962 200.154 601.11 195.245 603.427 190.858C605.537 187.009 608.753 183.881 612.66 181.88C616.814 179.781 621.417 178.724 626.071 178.802C632.788 178.603 639.317 181.039 644.26 185.591C649.082 190.132 651.501 196.639 651.516 205.113V326.102H737.693V185.336C737.983 170.956 734.337 156.771 727.149 144.314C720.186 132.568 710.228 122.882 698.293 116.247Z"/>
                        <path d="M873.293 21.7914H787.304V110.358H741.249V180.524H787.304V326.102H873.293V180.524H919.359V110.358H873.293V21.7914Z"/>
                        <path d="M1096.14 109.458C1087.77 106.95 1079.06 105.765 1070.32 105.947C1048.52 105.947 1030.27 111.184 1015.58 121.658V110.247H929.203V326.101H1015.66V209.435C1015.66 199.806 1017.86 192.398 1022.27 187.213C1026.68 182.028 1033.3 179.472 1042.12 179.546C1046.87 179.455 1051.58 180.27 1056.01 181.946C1059.44 183.203 1062.54 185.234 1065.06 187.88L1113.58 119.535C1108.64 114.849 1102.66 111.396 1096.14 109.458Z"/>
                        <path d="M1278.46 119.78C1259.99 109.98 1238.88 105.084 1215.12 105.091C1191.37 105.099 1170.2 109.995 1151.62 119.78C1133.67 128.986 1118.55 142.891 1107.88 160.014C1097.21 177.43 1091.73 197.526 1092.07 217.947C1091.77 238.54 1097.33 258.794 1108.1 276.346C1118.8 293.617 1133.99 307.651 1152.05 316.946C1170.68 326.598 1191.87 331.443 1215.61 331.48C1239.35 331.517 1260.47 326.613 1278.95 316.769C1297.07 307.43 1312.23 293.214 1322.71 275.722C1333.19 258.23 1338.58 238.158 1338.26 217.769C1338.57 197.37 1333.01 177.311 1322.22 159.991C1311.46 142.946 1296.35 129.069 1278.46 119.78ZM1246.8 238.247C1244.03 243.788 1239.67 248.376 1234.28 251.424C1228.42 254.602 1221.83 256.188 1215.17 256.024C1208.65 256.157 1202.21 254.57 1196.5 251.424C1191.07 248.35 1186.64 243.775 1183.75 238.247C1180.58 232.231 1178.99 225.51 1179.12 218.713C1178.96 211.764 1180.55 204.885 1183.75 198.713C1186.68 193.152 1191.09 188.512 1196.5 185.313C1202.17 182.028 1208.62 180.36 1215.17 180.491C1221.76 180.307 1228.29 181.9 1234.06 185.102C1239.39 188.231 1243.73 192.793 1246.59 198.269C1249.77 204.446 1251.35 211.323 1251.19 218.269C1251.35 225.184 1249.84 232.035 1246.8 238.247Z"/>
                        <path d="M1540.87 120.113C1524.9 110.595 1506.22 105.836 1484.82 105.836C1472.42 105.77 1460.1 107.922 1448.46 112.191C1444.85 113.551 1441.32 115.116 1437.89 116.88V4.75823H1351.66V326.202H1435.56V316.413C1439.64 318.952 1443.9 321.181 1448.31 323.079C1459.79 328.064 1472.17 330.607 1484.68 330.546C1506.08 330.546 1524.76 325.731 1540.72 316.102C1556.6 306.549 1569.53 292.795 1578.08 276.357C1587.01 259.535 1591.48 240.146 1591.48 218.191C1591.48 196.235 1587.03 176.795 1578.15 159.869C1569.68 143.39 1556.77 129.616 1540.87 120.113ZM1500.46 237.113C1497.5 242.58 1493.12 247.143 1487.78 250.313C1482.11 253.6 1475.64 255.268 1469.09 255.135C1462.56 255.266 1456.12 253.68 1450.4 250.535C1445.02 247.507 1440.6 243.015 1437.67 237.58C1434.49 231.649 1432.89 224.997 1433.05 218.269C1432.9 211.63 1434.43 205.062 1437.49 199.169C1440.39 193.775 1444.73 189.29 1450.02 186.213C1455.69 182.936 1462.14 181.269 1468.69 181.391C1475.3 181.296 1481.82 182.96 1487.58 186.213C1493.03 189.327 1497.51 193.888 1500.52 199.391C1503.57 205.221 1505.16 211.702 1505.16 218.28C1505.16 224.858 1503.57 231.338 1500.52 237.169L1500.46 237.113Z"/>
                        <path d="M1692.67 109.501H1605.85V326.101H1692.67V109.501Z"/>
                        <path d="M1690.55 28.2918C1688.41 22.7816 1685.2 17.7482 1681.11 13.4807C1676.99 9.22284 1672.06 5.83561 1666.61 3.51993C1661.15 1.20425 1655.29 0.00728702 1649.37 3.31688e-05C1643.44 -0.00722068 1637.58 1.17538 1632.12 3.4777C1626.66 5.78002 1621.72 9.15516 1617.59 13.4029C1613.45 17.637 1610.2 22.6557 1608.02 28.1643C1605.85 33.6729 1604.8 39.5603 1604.93 45.4806C1604.78 51.4163 1605.83 57.3217 1608 62.8471C1610.17 68.3724 1613.44 73.4052 1617.59 77.6472C1626.04 86.0181 1637.46 90.7141 1649.35 90.7141C1661.25 90.7141 1672.66 86.0181 1681.11 77.6472C1685.2 73.3846 1688.39 68.3551 1690.52 62.8492C1692.65 57.3434 1693.67 51.4703 1693.51 45.5695C1693.67 39.6714 1692.66 33.8006 1690.55 28.2918Z"/>
                        <path d="M1806.33 4.79134H1719.51V326.112H1806.33V4.79134Z"/>
                        <path d="M1920 4.79134H1834.08V326.112H1920V4.79134Z"/>
                        <path d="M442.326 122.713V122.535C425.015 113.343 405.063 108.75 382.471 108.758C361.031 108.414 339.849 113.465 320.871 123.447C303.22 132.754 288.398 146.639 277.96 163.646C267.59 180.58 262.405 200.017 262.405 221.957C262.405 244.179 267.757 263.857 278.46 280.99C289.294 298.244 304.76 312.105 323.093 320.99C342.138 330.413 364.282 335.12 389.526 335.113C407.407 335.428 425.182 332.301 441.882 325.901C453.569 321.222 464.301 314.444 473.548 305.901L437.893 255.757C432.063 262.594 424.788 268.051 416.593 271.735C408.011 275.251 398.799 276.967 389.526 276.779C378.949 276.779 370.171 274.757 363.104 270.724C355.967 266.507 350.347 260.14 347.049 252.535C346.516 251.424 346.038 250.168 345.593 248.924L494.259 247.935C495.586 242.367 496.544 236.718 497.126 231.024C497.559 226.202 497.782 221.58 497.782 217.202C498.128 197.474 493.03 178.033 483.048 161.013C473.263 144.738 459.171 131.483 442.326 122.713ZM345.349 196.157C345.771 194.924 346.193 193.735 346.682 192.58C349.595 184.955 354.722 178.377 361.404 173.691C368.185 169.216 376.171 166.921 384.293 167.113C391.748 166.819 399.114 168.812 405.404 172.824C411.26 176.992 415.65 182.903 417.949 189.713C418.64 191.622 419.233 193.565 419.726 195.535L345.349 196.157Z"/>
                        <path d="M202.461 250.647C192.328 254.713 180.742 256.743 167.705 256.735C156.734 256.848 145.836 254.93 135.561 251.08C126.237 247.175 117.811 241.401 110.806 234.113C103.659 226.562 98.0476 217.694 94.2835 208.002C90.3827 196.664 88.4768 184.736 88.6502 172.747C88.4725 161.044 90.38 149.403 94.2835 138.369C97.9745 128.509 103.591 119.482 110.806 111.814C117.759 104.57 126.206 98.9281 135.561 95.2804C145.862 91.4264 156.786 89.509 167.783 89.6249C181.117 89.6249 192.702 91.799 202.539 96.1471C212.047 100.13 220.624 106.043 227.728 113.514L275.116 46.7695C262.472 37.3787 248.559 29.8294 233.794 24.3473C213.794 16.8066 191.783 13.0362 167.761 13.0362C144.013 13.0362 121.995 17.1103 101.706 25.2584C81.9388 32.7582 63.7873 43.9666 48.2281 58.2805C33.1167 72.5755 21.0067 89.7395 12.606 108.769C4.20605 128.214 0.00606764 149.54 0.00606764 172.747C-0.184219 194.707 4.10301 216.476 12.606 236.724C21.1238 255.921 33.3808 273.228 48.6615 287.635C64.1546 302.25 82.2936 313.774 102.106 321.591C123.36 329.54 145.892 333.521 168.583 333.335C194.361 333.335 216.805 329.709 235.916 322.457C249.841 316.994 263.019 309.792 275.138 301.024L227.805 234.358C220.56 241.463 211.934 247.007 202.461 250.647Z"/>
                    </svg>
            </Wrapper>
        )
    }
}

export class FC_LOGO extends Component {
    render() {
        return (
            <Wrapper>
                <svg className="logoFC" width="143" height="18" viewBox="0 0 143 18" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 17.1818V0.818184H12.2417V4.69568H4.47157V7.76064H11.7705V11.5456H4.47157V17.1818H0Z"
                          fill="white"/>
                    <path
                        d="M24.1223 14.4888H16.9839L15.9311 17.1818H11.1387L18.2973 0.818184H22.8591L29.9975 17.1818H25.165L24.1223 14.4888ZM20.5631 5.39219L18.5078 10.6035H22.5884L20.5631 5.39219Z"
                        fill="white"/>
                    <path
                        d="M41.2668 9.77653C41.1565 7.81207 41.1565 7.06126 41.1264 6.43386V0.818184H45.6682V17.1818H41.8383L35.1811 8.36747L35.2512 11.9467V17.1818H30.6794V0.818184H34.5093L41.2668 9.77653Z"
                        fill="white"/>
                    <path
                        d="M62.5719 15.0378C60.7673 16.86 58.5816 17.5909 56.1654 17.5909C49.8992 17.5909 47.4528 13.5451 47.4328 9.0978C47.4127 4.88727 50.1699 0.409088 56.1654 0.409088C58.4713 0.409088 60.5467 1.23266 62.3413 3.03423L59.5642 5.84468C58.4212 4.72256 57.2381 4.41372 56.1654 4.41372C53.0874 4.41372 51.8743 6.98739 51.9043 9.11839C51.9244 11.2494 53.0172 13.6583 56.1654 13.6583C57.2381 13.6583 58.7922 13.1951 59.9251 12.0009L62.5719 15.0378Z"
                        fill="white"/>
                    <path
                        d="M76.5981 17.1818H63.8251V0.818184H76.2773V4.69568H68.2766V7.0304H75.8261V10.7948H68.2766V13.2735H76.5981V17.1818Z"
                        fill="white"/>
                    <path
                        d="M89.1306 9.77653C89.0203 7.81207 89.0203 7.06126 88.9903 6.43386V0.818184H93.532V17.1818H89.7021L83.0449 8.36747L83.115 11.9467V17.1818H78.5532V0.818184H82.3831L89.1306 9.77653Z"
                        fill="white"/>
                    <path d="M99.5475 4.62368H94.8955V0.818184H108.711V4.63397H104.039V17.1818H99.5475V4.62368Z"
                          fill="white"/>
                    <path
                        d="M124.853 17.1818H119.88L116.16 11.8747H114.586V17.1818H110.075V0.818184H117.875C121.955 0.818184 124.071 3.51289 124.071 6.45443C124.071 8.47032 123.389 10.1777 120.692 11.3913L124.843 16.9144V17.1818H124.853ZM114.596 4.51054V8.43946H117.925C119.108 8.43946 119.639 7.52409 119.639 6.547C119.639 5.51849 119.068 4.51054 117.885 4.51054H114.596Z"
                        fill="white"/>
                    <path
                        d="M134.137 17.5909C128.913 17.5909 125.264 14.4838 125.264 9C125.264 3.61911 128.913 0.409088 134.137 0.409088C139.381 0.409088 143 3.54709 143 9C142.99 14.4838 139.371 17.5909 134.137 17.5909ZM134.137 4.37017C131.56 4.37017 129.786 6.28383 129.786 9C129.786 11.7367 131.59 13.6298 134.137 13.6298C136.784 13.6298 138.488 11.7162 138.488 9C138.488 6.20152 136.774 4.37017 134.137 4.37017Z"
                        fill="white"/>
                </svg>
            </Wrapper>
        )
    }
}

export class SUCCESS extends Component {
    render() {
        const {size, template} = this.props;
        return (
            <Wrapper>
                <svg className={"successSVG_" + size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125 125">
                    <g transform="translate(-480 -384)">
                        <path
                            d="M534.058,396.412a41.646,41.646,0,1,1-41.646,41.646,41.695,41.695,0,0,1,41.646-41.646m0-10.412a52.058,52.058,0,1,0,52.058,52.058A52.057,52.057,0,0,0,534.058,386Z"
                            transform="translate(8.442 8.442)" fill={template == "fc" ? "#3F3456" : "#2ac1b6"}/>
                    </g>
                    <path
                        d="M503.665,433.772a5.2,5.2,0,0,1-3.691-1.53L489.531,421.8a5.22,5.22,0,1,1,7.383-7.383l5.942,5.942,17.35-26.033a5.222,5.222,0,1,1,8.688,5.8l-20.885,31.327a5.219,5.219,0,0,1-3.827,2.3C504,433.761,503.837,433.772,503.665,433.772Z"
                        transform="translate(-446.386 -350.387)" fill={template == "fc" ? "#3F3456" : "#2ac1b6"}/>
                </svg>
            </Wrapper>
        )
    }
}


export class SPINNER extends Component {
    render() {
        return (
            <Wrapper>
                <svg className="spinnerSVG" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 52.085">
                    <g transform="translate(-9.142)">
                        <g transform="translate(9.142)">
                            <path d="M73.217,324.332a4.7,4.7,0,1,0,3.307,1.368A4.525,4.525,0,0,0,73.217,324.332Z"
                                  transform="translate(-61.748 -287.367)"/>
                            <path
                                d="M19.569,197.071a5.228,5.228,0,0,0-5.213-5.214,5.213,5.213,0,1,0,3.682,8.9A5.018,5.018,0,0,0,19.569,197.071Z"
                                transform="translate(-9.142 -169.987)"/>
                            <path d="M345.218,92.008a2.614,2.614,0,1,0-1.841-.766A2.511,2.511,0,0,0,345.218,92.008Z"
                                  transform="translate(-304.601 -76.899)"/>
                            <path d="M65.128,59.388a5.729,5.729,0,1,0,4.056,1.678A5.527,5.527,0,0,0,65.128,59.388Z"
                                  transform="translate(-53.658 -52.61)"/>
                            <path d="M398.213,211.045a3.148,3.148,0,1,0,.912,2.216A3.015,3.015,0,0,0,398.213,211.045Z"
                                  transform="translate(-349.125 -186.177)"/>
                            <path d="M337.124,333.473a3.671,3.671,0,1,0,2.574,1.075A3.512,3.512,0,0,0,337.124,333.473Z"
                                  transform="translate(-296.507 -295.465)"/>
                            <path d="M205.169,383.717a4.174,4.174,0,1,0,2.949,1.223A4.021,4.021,0,0,0,205.169,383.717Z"
                                  transform="translate(-179.127 -339.975)"/>
                            <path
                                d="M188.983,0a6.035,6.035,0,0,0-4.432,1.824,6.035,6.035,0,0,0-1.824,4.432,6.033,6.033,0,0,0,1.824,4.431,6.294,6.294,0,0,0,8.863,0,6.033,6.033,0,0,0,1.824-4.431,6.036,6.036,0,0,0-1.824-4.432A6.032,6.032,0,0,0,188.983,0Z"
                                transform="translate(-162.942 0)"/>
                        </g>
                    </g>
                </svg>
            </Wrapper>
        )
    }
}

export class SHOWQR extends Component {
    render() {
        const {width, height, inline} = this.props;
        return (
            <Fragment>
                {!inline &&
                    <Wrapper>
                        <svg className="qrAction showQR" width={width} height={height} viewBox="0 0 450 450"
                             xmlns="http://www.w3.org/2000/svg">
                            <g>
                                <path fill="#008F8C"
                                      d="m1,49c0,-26.5 21.5,-48 48,-48l96,0c26.5,0 48,21.5 48,48l0,96c0,26.5 -21.5,48 -48,48l-96,0c-26.5,0 -48,-21.5 -48,-48l0,-96zm64,16l0,64l64,0l0,-64l-64,0zm-64,240c0,-26.5 21.5,-48 48,-48l96,0c26.5,0 48,21.5 48,48l0,96c0,26.5 -21.5,48 -48,48l-96,0c-26.5,0 -48,-21.5 -48,-48l0,-96zm64,16l0,64l64,0l0,-64l-64,0zm240,-320l96,0c26.5,0 48,21.5 48,48l0,96c0,26.5 -21.5,48 -48,48l-96,0c-26.5,0 -48,-21.5 -48,-48l0,-96c0,-26.5 21.5,-48 48,-48zm80,64l-64,0l0,64l64,0l0,-64zm-128,208c0,-8.8 7.2,-16 16,-16l64,0c8.8,0 16,7.2 16,16s7.2,16 16,16l32,0c8.8,0 16,-7.2 16,-16s7.2,-16 16,-16s16,7.2 16,16l0,96c0,8.8 -7.2,16 -16,16l-64,0c-8.8,0 -16,-7.2 -16,-16s-7.2,-16 -16,-16s-16,7.2 -16,16l0,64c0,8.8 -7.2,16 -16,16l-32,0c-8.8,0 -16,-7.2 -16,-16l0,-160zm112,176a16,16 0 1 1 0,-32a16,16 0 1 1 0,32zm64,0a16,16 0 1 1 0,-32a16,16 0 1 1 0,32z"/>
                                <path fill="#6b6d72"
                                      d="m224.02,114c-40.4,0 -72.76,18.4 -96.31,40.3c-23.4,21.7 -39.05,47.7 -46.45,65.56c-1.65,3.95 -1.65,8.35 0,12.3c7.4,17.85 23.05,43.85 46.45,65.56c23.55,21.9 55.9,40.3 96.31,40.3s72.76,-18.4 96.31,-40.3c23.4,-21.75 39.05,-47.7 46.5,-65.56c1.65,-3.95 1.65,-8.35 0,-12.3c-7.45,-17.85 -23.1,-43.85 -46.5,-65.56c-23.55,-21.9 -55.9,-40.3 -96.31,-40.3zm-72.01,112.01a72.01,72.01 0 1 1 144.01,0a72.01,72.01 0 1 1 -144.01,0zm72.01,-32c0,17.65 -14.35,32 -32,32c-3.55,0 -6.95,-0.6 -10.15,-1.65c-2.75,-0.9 -5.95,0.8 -5.85,3.7c0.15,3.45 0.65,6.9 1.6,10.35c6.85,25.6 33.2,40.8 58.81,33.95s40.8,-33.2 33.95,-58.81c-5.55,-20.75 -23.9,-34.7 -44.3,-35.55c-2.9,-0.1 -4.6,3.05 -3.7,5.85c1.05,3.2 1.65,6.6 1.65,10.15l-0.01,0.01z"/>
                            </g>
                        </svg>
                    </Wrapper>
                }
                {inline &&
                    <svg className="qrAction showQR" width={width} height={height} viewBox="0 0 450 450"
                         xmlns="http://www.w3.org/2000/svg">
                        <g>
                            <path fill="#008F8C"
                                  d="m1,49c0,-26.5 21.5,-48 48,-48l96,0c26.5,0 48,21.5 48,48l0,96c0,26.5 -21.5,48 -48,48l-96,0c-26.5,0 -48,-21.5 -48,-48l0,-96zm64,16l0,64l64,0l0,-64l-64,0zm-64,240c0,-26.5 21.5,-48 48,-48l96,0c26.5,0 48,21.5 48,48l0,96c0,26.5 -21.5,48 -48,48l-96,0c-26.5,0 -48,-21.5 -48,-48l0,-96zm64,16l0,64l64,0l0,-64l-64,0zm240,-320l96,0c26.5,0 48,21.5 48,48l0,96c0,26.5 -21.5,48 -48,48l-96,0c-26.5,0 -48,-21.5 -48,-48l0,-96c0,-26.5 21.5,-48 48,-48zm80,64l-64,0l0,64l64,0l0,-64zm-128,208c0,-8.8 7.2,-16 16,-16l64,0c8.8,0 16,7.2 16,16s7.2,16 16,16l32,0c8.8,0 16,-7.2 16,-16s7.2,-16 16,-16s16,7.2 16,16l0,96c0,8.8 -7.2,16 -16,16l-64,0c-8.8,0 -16,-7.2 -16,-16s-7.2,-16 -16,-16s-16,7.2 -16,16l0,64c0,8.8 -7.2,16 -16,16l-32,0c-8.8,0 -16,-7.2 -16,-16l0,-160zm112,176a16,16 0 1 1 0,-32a16,16 0 1 1 0,32zm64,0a16,16 0 1 1 0,-32a16,16 0 1 1 0,32z"/>
                            <path fill="#6b6d72"
                                  d="m224.02,114c-40.4,0 -72.76,18.4 -96.31,40.3c-23.4,21.7 -39.05,47.7 -46.45,65.56c-1.65,3.95 -1.65,8.35 0,12.3c7.4,17.85 23.05,43.85 46.45,65.56c23.55,21.9 55.9,40.3 96.31,40.3s72.76,-18.4 96.31,-40.3c23.4,-21.75 39.05,-47.7 46.5,-65.56c1.65,-3.95 1.65,-8.35 0,-12.3c-7.45,-17.85 -23.1,-43.85 -46.5,-65.56c-23.55,-21.9 -55.9,-40.3 -96.31,-40.3zm-72.01,112.01a72.01,72.01 0 1 1 144.01,0a72.01,72.01 0 1 1 -144.01,0zm72.01,-32c0,17.65 -14.35,32 -32,32c-3.55,0 -6.95,-0.6 -10.15,-1.65c-2.75,-0.9 -5.95,0.8 -5.85,3.7c0.15,3.45 0.65,6.9 1.6,10.35c6.85,25.6 33.2,40.8 58.81,33.95s40.8,-33.2 33.95,-58.81c-5.55,-20.75 -23.9,-34.7 -44.3,-35.55c-2.9,-0.1 -4.6,3.05 -3.7,5.85c1.05,3.2 1.65,6.6 1.65,10.15l-0.01,0.01z"/>
                        </g>
                    </svg>
                }
            </Fragment>
        )
    }
}

export class HIDEQR extends Component {
    render() {
        const {width, height, inline} = this.props;
        return (
            <Fragment>
                {!inline &&
                    <Wrapper>
                        <svg className="qrAction hideQR" width={width} height={height} viewBox="0 0 450 450"
                             xmlns="http://www.w3.org/2000/svg">
                            <g>
                                <path fill="#008F8C"
                                      d="m1,49c0,-26.5 21.5,-48 48,-48l96,0c26.5,0 48,21.5 48,48l0,96c0,26.5 -21.5,48 -48,48l-96,0c-26.5,0 -48,-21.5 -48,-48l0,-96zm64,16l0,64l64,0l0,-64l-64,0zm-64,240c0,-26.5 21.5,-48 48,-48l96,0c26.5,0 48,21.5 48,48l0,96c0,26.5 -21.5,48 -48,48l-96,0c-26.5,0 -48,-21.5 -48,-48l0,-96zm64,16l0,64l64,0l0,-64l-64,0zm240,-320l96,0c26.5,0 48,21.5 48,48l0,96c0,26.5 -21.5,48 -48,48l-96,0c-26.5,0 -48,-21.5 -48,-48l0,-96c0,-26.5 21.5,-48 48,-48zm80,64l-64,0l0,64l64,0l0,-64zm-128,208c0,-8.8 7.2,-16 16,-16l64,0c8.8,0 16,7.2 16,16s7.2,16 16,16l32,0c8.8,0 16,-7.2 16,-16s7.2,-16 16,-16s16,7.2 16,16l0,96c0,8.8 -7.2,16 -16,16l-64,0c-8.8,0 -16,-7.2 -16,-16s-7.2,-16 -16,-16s-16,7.2 -16,16l0,64c0,8.8 -7.2,16 -16,16l-32,0c-8.8,0 -16,-7.2 -16,-16l0,-160zm112,176a16,16 0 1 1 0,-32a16,16 0 1 1 0,32zm64,0a16,16 0 1 1 0,-32a16,16 0 1 1 0,32z"/>
                                <path fill="#6b6d72"
                                      d="m83.4,100.55c-5.2,-4.1 -12.75,-3.15 -16.85,2.05s-3.15,12.75 2.05,16.85l296.01,232.01c5.2,4.1 12.75,3.15 16.85,-2.05s3.15,-12.75 -2.05,-16.85l-52.6,-41.2c19.8,-20.3 33.2,-43.05 39.95,-59.2c1.65,-3.95 1.65,-8.35 0,-12.3c-7.45,-17.85 -23.1,-43.85 -46.5,-65.55c-23.5,-21.9 -55.86,-40.3 -96.25,-40.3c-34.1,0 -62.5,13.15 -84.66,30.4l-55.95,-43.85l0,-0.01zm92.15,72.2c12.75,-11.65 29.8,-18.75 48.45,-18.75c39.75,0 72,32.25 72,72c0,12.45 -3.15,24.15 -8.7,34.35l-19.3,-15.1c4.2,-9.65 5.3,-20.7 2.4,-31.65c-5.55,-20.75 -23.9,-34.7 -44.3,-35.55c-2.9,-0.1 -4.6,3.05 -3.7,5.85c1.05,3.2 1.65,6.6 1.65,10.15c0,5.1 -1.2,9.9 -3.3,14.15l-45.15,-35.4l-0.05,-0.05l0.01,0zm74.95,120.2c-8.2,3.25 -17.15,5.05 -26.51,5.05c-39.75,0 -72,-32.25 -72,-72c0,-3.45 0.25,-6.8 0.7,-10.1l-47.15,-37.15c-11.4,14.85 -19.55,29.65 -24.3,41.1c-1.65,3.95 -1.65,8.35 0,12.3c7.45,17.85 23.1,43.85 46.5,65.55c23.5,21.9 55.86,40.3 96.25,40.3c23.9,0 44.95,-6.45 63.1,-16.25l-36.6,-28.8l0.01,0.02z"/>
                            </g>
                        </svg>
                    </Wrapper>
                }
            </Fragment>
        )
    }
}

export class COPY extends Component {
    render() {
        const {width, height, inline} = this.props;
        return (
            <Wrapper>
                <svg className="qrAction copyIcon" width={width} height={height} xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 448 512">
                    <path fill="#008F8C"
                          d="M433.941 65.941l-51.882-51.882A48 48 0 0 0 348.118 0H176c-26.51 0-48 21.49-48 48v48H48c-26.51 0-48 21.49-48 48v320c0 26.51 21.49 48 48 48h224c26.51 0 48-21.49 48-48v-48h80c26.51 0 48-21.49 48-48V99.882a48 48 0 0 0-14.059-33.941zM266 464H54a6 6 0 0 1-6-6V150a6 6 0 0 1 6-6h74v224c0 26.51 21.49 48 48 48h96v42a6 6 0 0 1-6 6zm128-96H182a6 6 0 0 1-6-6V54a6 6 0 0 1 6-6h106v88c0 13.255 10.745 24 24 24h88v202a6 6 0 0 1-6 6zm6-256h-64V48h9.632c1.591 0 3.117.632 4.243 1.757l48.368 48.368a6 6 0 0 1 1.757 4.243V112z"/>
                </svg>
            </Wrapper>
        )
    }
}

