export const EMPTY_CURRENCY = '';
export const NOT_CRYPTO_CURRENCIES = ['AUD','BRL','CAD','CHF','EUR','GBP','USD','BTC','MXN','UAH','NOK','DKK','SEK','JPY','INR','PLN','LTC','DSH','ETH','INR','TWD','HKD','MYR','IDR','RUB','PEN','SGD'];
export const CRYPTO_CURRENCIES_LIST = {
    'DOGE': {
        'title': 'DOGE',
        'longTitle': 'Dogecoin'
    },
    'BTC': {
        'title': 'BTC',
        'longTitle': 'Bitcoin'
    },
    'LTC': {
        'title': 'LTC',
        'longTitle': 'Litecoin'
    },
    'ETH': {
        'title': 'ETH',
        'longTitle': 'Ethereum (ETH)'
    },
    'DASH': {
        'title': 'DASH',
        'longTitle': 'Dash'
    },
    'XMR': {
        'title': 'XMR',
        'longTitle': 'Monero'
    },
    'BCH': {
        'title': 'BCH',
        'longTitle': 'Bitcoin Cash'
    },
    'NAFTY': {
        'title': 'NAFTY',
        'longTitle': 'NAFTY'
    },
    'BNB': {
        'title': 'BNB',
        'longTitle': 'BNB Smart Chain'
    },
    'TRX': {
        'title': 'TRX',
        'longTitle': 'TRX'
    },
    'USDT_ETH': {
        'title': 'ETH / USDT',
        'longTitle': 'USDT Ethereum (ERC20)'
    },
    'USDT_BNB': {
        'title': 'BNB / USDT',
        'longTitle': 'USDT BNB Smart Chain (BEP20)'
    },
    'USDT_TRX': {
        'title': 'TRX / USDT',
        'longTitle': 'USDT Tron (TRC20)'
    }
}

// export const CRYPTO_CURRENCIES = ['DOGE', 'BTC', 'LTC', 'ETH', 'DASH', 'XMR', 'BCH', 'NAFTY', 'BNB', 'TRX', 'USDT_ETH', 'USDT_BNB', 'USDT_TRX'];
export const CRYPTO_CURRENCIES = Object.keys(CRYPTO_CURRENCIES_LIST)
