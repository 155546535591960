import React, {Component} from 'react';
import styled from 'styled-components';

import NavigationMenu from '../../molecules/navigationMenu';
import RightsText from '../../molecules/rightsText';

import {Mobile, Tablet, TabletLandscape, MobileLandscape, Desktop, LargeDesktop} from '../../../global_styles/responsive_utilities/breakpoins'

import {connect} from 'react-redux';
import {mapStateToProps} from './container';
import {CRYPTO_CURRENCIES} from "../../../environment/supported_currencies";

const StyledWrapper = styled.div`
  height: 100%;
  padding: 0 7vw;

  @media (max-width: 320px) {
    padding: 0 2vw;
  }
`;

const LandscapeWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const NavWrapper = styled.div`
  width: 45%;
  @media (min-width: 1000px) {
    width: 40%;
  }
  @media (min-width: 1200px) {
    width: 35%;
  }
`

class Footer extends Component {
    render() {
        const {order, template} = this.props
        function noPopup () {
            return !((order.status === 'pending' && CRYPTO_CURRENCIES.includes(order.currency)) ||
                order.status === 'processing' ||
                order.status === 'successful' ||
                order.status === 'failed' ||
                order.status === 'expired' ||
                order.status === 'canceled')
        }
        return (
            <StyledWrapper>
                <LargeDesktop>
                    <LandscapeWrapper>
                        <RightsText template={template}/>
                        <NavWrapper>
                            {noPopup() &&
                                <NavigationMenu template={template}/>
                            }
                        </NavWrapper>
                    </LandscapeWrapper>
                </LargeDesktop>
                <Desktop>
                    <LandscapeWrapper>
                        <RightsText template={template}/>
                        <NavWrapper>
                            {noPopup() &&
                                <NavigationMenu template={template}/>
                            }
                        </NavWrapper>
                    </LandscapeWrapper>
                </Desktop>
                <TabletLandscape>
                    <LandscapeWrapper>
                        <RightsText template={template}/>
                        <NavWrapper>
                            {noPopup() &&
                                <NavigationMenu template={template}/>
                            }
                        </NavWrapper>
                    </LandscapeWrapper>
                </TabletLandscape>
                <MobileLandscape>
                    <RightsText template={template}/>
                </MobileLandscape>
                <Tablet>
                    <RightsText template={template}/>
                </Tablet>
                <Mobile>
                    <RightsText template={template}/>
                </Mobile>
            </StyledWrapper>
        );
    }
}

export default connect(mapStateToProps)(Footer);