import React, {Component} from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { ReactComponent as BtcSVG } from './assets/coins/btc.svg';
import { ReactComponent as LtcSVG } from './assets/coins/ltc.svg';
import { ReactComponent as EthSVG } from './assets/coins/eth.svg';
import { ReactComponent as DashSVG } from './assets/coins/dash.svg';
import { ReactComponent as DefaultCoinSVG } from './assets/coins/walet.svg';
import {ReactComponent as DogeCoinSVG} from './assets/coins/doge.svg'
import {ReactComponent as BchSVG} from './assets/coins/bch.svg'
import {ReactComponent as XMRSVG} from './assets/coins/xmr.svg'
import {ReactComponent as NaftySVG} from './assets/coins/nafty.svg'
import {ReactComponent as BnbSVG} from './assets/coins/bnb.svg'
import {ReactComponent as TrxSVG} from './assets/coins/trx.svg'
import {ReactComponent as UsdtEthSVG} from './assets/coins/usdt_eth.svg'
import {ReactComponent as UsdtBnbSVG} from './assets/coins/usdt_bnb.svg'
import {ReactComponent as UsdtTrxSVG} from './assets/coins/usdt_trx.svg'
/*ADD NEW COIN ICON IMPORT HERE*/

const IconWrapper = styled.div`
  .coinSVG_big{
      margin-bottom: 1.4vh;  
      min-height: 4em;
      @media (max-width: 767px) {
        height: 7vh;
      }
      @media (min-width: 768px) and (orientation: landscape) {
        height: 10vh;
      }
      @media (min-width: 768px) and (orientation: portrait) {
        height: 7vh;
      }
  }
  .coinSVG_small{
      margin-right: 1vh;
      height: 2.5em;
      width:  2.5em;
        @media screen and (max-width: 414px) and (orientation: portrait) {
        height: 2.5em;
        width:  2.5em;
      }
       @media screen and (max-width: 767px) and (orientation: landscape) {
        height: 2.5em;
        width:  2.5em;
      }
            @media screen and (max-width: 768px) and (orientation: portrait) {
        height: 3em;
        width:  3em;

      }
          @media screen and (max-width: 1024px) and (orientation: portrait) {
        height: 2.5em;
        width:  2.5em;
      }
      @media screen and (max-width: 1024px) and (orientation: landscape) {
        height: 2.5em;
        width:  2.5em;

      }
  }
`;

const svgs = require.context('./assets/coins', true, /\.svg$/);

const coinsSVGsSet = svgs.keys()
    .reduce((images, path, coinName) => {
        coinName = path.replace(/[\/.]+/, "").slice(0,-4).toUpperCase();
        images[coinName] = path;
        return images;
    }, {});


export default class CoinIcon extends Component {
    render() {
        let {icon, size} = this.props;
        icon = Object.keys(coinsSVGsSet).indexOf(icon) >= 0 ? icon : 'Default';
        const IconComponent = {
            'BTC' : BtcSVG,
            'LTC' : LtcSVG,
            'ETH' : EthSVG,
            'DASH' : DashSVG,
            'DOGE': DogeCoinSVG,
            'BCH' : BchSVG,
            'XMR' : XMRSVG,
            'NAFTY' : NaftySVG,
            'BNB' : BnbSVG,
            'TRX' : TrxSVG,
            'USDT_ETH': UsdtEthSVG,
            'USDT_BNB': UsdtBnbSVG,
            'USDT_TRX': UsdtTrxSVG,
            'Default' : DefaultCoinSVG
            /*ADD '[CURRENCY_ABBR]' : [COMPONENT_NAME] TO THE OBJECT BELOW [COMPONENT_NAME] = [CURRENCY_ABBR]+'SVG'*/
        }[icon];
        return (
            <IconWrapper data-icon={icon}>
                <IconComponent className={"coinSVG_" + size} />
            </IconWrapper>
        );
    }
}



CoinIcon.propTypes = {
    icon: PropTypes.string,
    size: PropTypes.string,
};
