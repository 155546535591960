export const FIRST_LOAD_ORDER = 'FIRST_LOAD_ORDER';
export const FIRST_LOAD_ORDER_SUCCESS = 'FIRST_LOAD_ORDER:SUCCESS';
export const FIRST_LOAD_ORDER_ERROR = 'FIRST_LOAD_ORDER:ERROR';

export const LOAD_ORDER = 'LOAD_ORDER';
export const LOAD_ORDER_SUCCESS = 'LOAD_ORDER:SUCCESS';
export const LOAD_ORDER_ERROR = 'LOAD_ORDER:ERROR';

export const SEND_CURRENCY = 'SEND_CURRENCY';
export const SEND_CURRENCY_SUCCESS = 'SEND_CURRENCY:SUCCESS';
export const SEND_CURRENCY_ERROR = 'SEND_CURRENCY:ERROR';

export const SEND_STATUS = 'SEND_STATUS';
export const SEND_STATUS_SUCCESS = 'SEND_STATUS:SUCCESS';
export const SEND_STATUS_ERROR = 'SEND_STATUS:ERROR';

export const LOAD_COINS = 'LOAD_COINS';
export const LOAD_COINS_SUCCESS = 'LOAD_COINS:SUCCESS';
export const LOAD_COINS_ERROR = 'LOAD_COINS:ERROR';

export const SET_TIME_LEFT = 'SET_TIME_LEFT';
export const INNIT_ORDER_AJAX_SAGA = 'INNIT_ORDER_AJAX_SAGA';
export const INNIT_WS_SAGA = 'INNIT_WS_SAGA';

export const SOCKET_CLOSE = 'SOCKET_CLOSE';
export const SOCKET_ERROR = 'SOCKET_ERROR';
export const SOCKET_MESSAGE = 'SOCKET_MESSAGE';
export const SOCKET_CONNECT = 'SOCKET_CONNECT';

export const NOT_OPEN_POPUP = 'NOT_OPEN_POPUP';
