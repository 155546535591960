import React, {Component} from 'react';

import 'semantic-ui-css/semantic.min.css';
import TitleText from '../../molecules/titleText';

class PrivacyPage extends Component {
  componentDidMount() {
    document.title = "CoinCentro|privacy";
  }

  render() {
    return <TitleText>Privacy</TitleText>;
  }
}

export default PrivacyPage;
