import {setTimeLeft, sendStatus} from '../../../logic/order/actions';

export function mapStateToProps(state) {
  const {order} = state;
  return {
    timeLeft: order.timeLeft,
    order: order.info
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    setTimeLeft(timeleft) {
      dispatch(setTimeLeft(timeleft));
    },
    sendStatus(status) {
      dispatch(sendStatus(status));
    },
  };
}
