import React, {Component,  Fragment} from 'react';
import styled, {keyframes} from 'styled-components';

import Text from '../../atoms/text';
import ModalContent from '../../modals/order';
import Popup from 'reactjs-popup';
import Loader from '../../atoms/loader';

import CoinIcon from "../../atoms/svg/coin";

import {connect} from 'react-redux';
import {mapStateToProps, mapDispatchToProps} from './container';
import PropTypes from 'prop-types';

import {fadeIn} from 'react-animations';
import {
  CRYPTO_CURRENCIES,
  CRYPTO_CURRENCIES_LIST,
  EMPTY_CURRENCY,
  NOT_CRYPTO_CURRENCIES
} from "../../../environment/supported_currencies";

const Animation = keyframes`${fadeIn}`;

const ModalFadeIn = styled.div`
  animation: 0.5s ${Animation};
`;

const CoinsFadeIn = styled.div`
  animation: 0.9s ${Animation};
`;

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${props => props.template === 'fc' ? '#F1F0F2' : props.theme.colors.text.quinary};
  border-radius: 6px;
  justify-content: flex-start;
  padding: 1.5rem 0;
  margin-bottom: 1rem;
  min-height: 10em;
  @media (max-width: 900px) {
    width: 48%;
    margin-right: ${props => ((props.order + 1) % 2 === 0 ? '0' : '2vw')};
  }
  @media screen and (min-width: 901px) and (orientation: landscape) {
    width: 18%;
    height: auto;
    margin-right: ${props => ((props.order + 1) % 5 === 0 ? '0' : '2vw')};
  }
  @media (orientation: portrait) {
    width: 48%;
    margin-right: ${props => ((props.order + 1) % 2 === 0 ? '0' : '2vw')};
  }

  :hover {
    cursor: pointer;
    box-shadow: 0px 0px 20px -10px ${props => props.theme.colors.bg.senary};
  }
  background-color: ${props => props.template === 'fc' ? '#F7F7FA' : 'none'}
`;

const StyledCardsWrapper = styled.div`
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  height: 100%;
  @media (max-width: 900px) {
    justify-content: space-between;
    overflow:hidden;
  }
  @media (min-width: 901px) {
    justify-content: start;
    overflow:hidden;
  }
  @media (min-width: 768px) and (orientation: portrait) {
    justify-content: start;
    overflow:hidden;
  }
  padding: 2vh 0;
`;

const StyledText = styled(Text)`
  margin-bottom: 2vh;
  @media screen and (max-width: 1023px) and (orientation: landscape) {
    margin-bottom: 2vw;
  }
`;




const StyledPopupWrapper = styled.div`
  .popup-content {
    border-radius: 15px;
    box-shadow: 0px 0px 20px -10px ${props => props.theme.colors.bg.senary};
    border: none !important;
    min-width: 300px !important;
    min-height: 130px !important;
    max-width: 90% !important;
    width: 23rem !important;
  }

  .popup-overlay {
    padding: 1rem 0;
    overflow-y: auto;
    background: rgba(240, 240, 240, 0.7) !important;
    @media screen and (max-width: 1023px) and (orientation: landscape) {
      overflow-y: scroll;
    }
  }
`;

class CoinsMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
    };
  }

  handleCardClick = currency => {
    const {sendCurrency} = this.props;
    sendCurrency(currency);
  };

  handleCloseModal = () => {
    this.setState({modalOpen: false});
  };

  renderCards = () => {
    const {coins, template} = this.props;
    let coinsArray = Object.entries(coins)
    return coinsArray.map((coin, i) => {
      const coin_title = CRYPTO_CURRENCIES_LIST[coin[0]].longTitle
      return (
        <StyledCard
          template={template}
          key={i}
          order={i}
          onClick={() => this.handleCardClick(coin[0])}>
          <CoinIcon icon={coin[0]} size={'big'} />
          <Text grey="true" medium="true" align="center" fontSize="0.85rem" whiteSpace="break-spaces">{coin[1]}<br/>{coin_title}</Text>
        </StyledCard>
      );
    });
  };

  render() {
    const {order, currencyLoading, coinsLoading, shouldOpenPopup, template} = this.props;
    return (
      <Fragment>
        {order.status === 'pending' &&
        (order.currency === EMPTY_CURRENCY || NOT_CRYPTO_CURRENCIES.includes(order.currency)) &&
          !currencyLoading && !coinsLoading && (
            <Fragment>
              <CoinsFadeIn>
              <StyledText bold="true" grey="true" fontSize="0.88rem" template={template}>
                Please select a currency
              </StyledText>
              <StyledCardsWrapper>
                {this.renderCards()}
              </StyledCardsWrapper>
              </CoinsFadeIn>
            </Fragment>
          )}
        {(currencyLoading || coinsLoading) &&
          <Loader template={template} />
        }
        <StyledPopupWrapper>
          <Popup
            open={
              (shouldOpenPopup && (
                  (order.status === 'pending' && CRYPTO_CURRENCIES.includes(order.currency)) ||
                  order.status === 'processing' ||
                  order.status === 'successful' ||
                  order.status === 'failed' ||
                  order.status === 'expired' ||
                  order.status === 'canceled'
              ) &&
              currencyLoading === false)
            }
            onClose={this.handleCloseModal}
            modal
            closeOnDocumentClick={false}
            closeOnEscape={false}
            lockScroll>
            {close => (
              <ModalFadeIn>
                <ModalContent template={template} closeModal={close} />
              </ModalFadeIn>
            )}
          </Popup>
        </StyledPopupWrapper>
      </Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CoinsMenu);

CoinsMenu.propTypes = {
  coins: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array]).isRequired,
  order: PropTypes.shape({
    id: PropTypes.string,
    address: PropTypes.string,
    cost: PropTypes.number,
    amount: PropTypes.number,
    currency: PropTypes.oneOf([EMPTY_CURRENCY, ...NOT_CRYPTO_CURRENCIES, ...CRYPTO_CURRENCIES]).isRequired,
    email: PropTypes.string,
    user_id: PropTypes.string,
    order_id: PropTypes.string,
    status: PropTypes.string,
    product_name: PropTypes.string,
    site_name: PropTypes.string,
    created_at: PropTypes.string,
    expired_at: PropTypes.string,
    url_return: PropTypes.string.isRequired,
  }),
  sendCurrency: PropTypes.func,
};