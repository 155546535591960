import styled from 'styled-components';
import {Button} from 'semantic-ui-react';

import PropTypes from 'prop-types';

const StyledButton = styled(Button)`
  &&&& {
    :hover,
    :focus {
      background-color: ${props => props.template ? "#3F3456" : (props.filled ? props.theme.colors.text.tertiary : props.theme.body.bg)};
      color: ${props => (props.filled ? props.theme.body.bg : props.close ? props.theme.colors.text.primary : props.theme.colors.text.tertiary)};
    }
    padding: ${props => (props.close ? '' : props.theme.button.primary.initial.padding)};
    width: ${props => (props.close ? '' : '98px')};
    @media screen and (max-width: 320px) and (orientation: portrait) {
      width: ${props => (props.close ? '' : '85px')};
    }
    box-shadow: ${props => (props.filled ? '0px 3px 5px '+ (props.template ? "#3F3456" : props.theme.colors.bg.quinary) : 'none')};
    font-size: ${props => (props.close ? props.theme.fonts.sizes.xxl : props.theme.fonts.sizes.sm)};
  }
  &&& {
    background-color: ${props => props.template ? "#3F3456" :(props.filled ? props.theme.button.primary.initial.bg.color : props.theme.body.bg)};
    border: ${props => (props.close ? 'none' : '1px solid '+ props.template ? "#3F3456" : props.theme.button.primary.initial.bg.color)};
    ${props => props.theme.button.primary.initial.style}
    color: ${props => (props.filled ? props.theme.body.bg : props.close ? props.theme.colors.text.primary : props.theme.colors.text.tertiary)};
    font-weight: ${props => (props.close ? props.theme.fonts.weights.light : props.theme.fonts.weights.semibold)};
    display: ${props => (props.displayblock ? 'block' : '')};
  }
`;

export default StyledButton;

StyledButton.propTypes = {
  filled: PropTypes.oneOf(['true', undefined]),
  close: PropTypes.oneOf(['true', undefined]),
  displayblock: PropTypes.oneOf(['true', undefined]),
};
