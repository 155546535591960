import React, {Component} from 'react';


import 'semantic-ui-css/semantic.min.css';
import TitleText from '../../molecules/titleText';

class TermsPage extends Component {
  componentDidMount() {
    document.title = "CoinCentro|terms";
  }
  render() {
    return <TitleText>Terms</TitleText>;
  }
}

export default TermsPage;
