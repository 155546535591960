import styled from 'styled-components';

import PropTypes from 'prop-types';

const StyledSpan = styled.span`
  color: ${props =>
    props.template === 'fc' ? '#3F3456' 
        : props.blue
      ? props.theme.colors.text.tertiary
        : props.grey
      ? props.theme.colors.text.primary
        : props.lightGrey
      ? props.theme.colors.text.senary
        : props.green
      ? props.theme.colors.text.septenary
        : props.theme.colors.text.octonary};
  font-weight: ${props =>
    props.bold
      ? props.theme.fonts.weights.bold
      : props.medium
      ? props.theme.fonts.weights.semibold
      : props.regular
      ? props.theme.fonts.weights.normal
      : props.light
      ? props.theme.fonts.weights.light
      : props.theme.fonts.weights.normal};
  display: ${props => (props.displayblock ? 'block' : '')};
  font-size: ${props => props.fontSize};
  margin-bottom: 0;
  line-height: ${props => (props.lineHeight ? props.lineHeight : props.theme.body.font.lineHeight)};
  white-space:  ${props => (props.whiteSpace ? props.whiteSpace : "nowrap")};
  text-align: ${props => (props.align ? props.align : "inherit")};
`;

export default StyledSpan;

StyledSpan.propTypes = {
  blue: PropTypes.oneOf(['true', undefined]),
  grey: PropTypes.oneOf(['true', undefined]),
  lightGrey: PropTypes.oneOf(['true', undefined]),
  green: PropTypes.oneOf(['true', undefined]),
  bold: PropTypes.oneOf(['true', undefined]),
  medium: PropTypes.oneOf(['true', undefined]),
  regular: PropTypes.oneOf(['true', undefined]),
  light: PropTypes.oneOf(['true', undefined]),
  displayblock: PropTypes.oneOf(['true', undefined]),
  fontSize: PropTypes.string,
  align: PropTypes.oneOf(['left','right','center', undefined])
};
