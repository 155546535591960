const theme = {};
theme.name = 'default';
theme.colors = {
    text: {
        primary: '#6b6d72',
        secondary: '#97999c',
        tertiary: '#008F8C',
        quaternary: '#a2a2a2',
        quinary: '#f1f1f1',
        senary: '#C3C5C8',
        septenary: '#2AC1B6',
        octonary: '#000000',
        nonary: '',
        denary: '',
    },
    bg: {
        primary: '#ffffff',
        secondary: '#2dc7a11a',
        tertiary: '#eaeaea',
        quaternary: '#f6f6f6',
        quinary: '#008F8C4d',
        senary: '#8072804d',
        septenary: '#0000001a',
        octonary: '#ffac9dcc',
        nonary: '',
        denary: '',
    }
};
theme.fonts = {
    families: {
        primary: '\'Open Sans\', sans-serif',
        secondary: '',
        tertiary: '',
    },
    sizes: {
        primary: '14px',
        xxs: '0.63rem',
        xs: '0.69rem',
        sm: '0.75rem',
        md: '0.88rem',
        lg: '1rem',
        xl: '1.12rem',
        xxl: '2.2rem',
    },
    weights: {
        lighter: '',
        light: '300',
        semilight: '',
        normal: '400',
        semibold: '600',
        bold: '700',
        bolder: '',
    },
    lineHeights: {
        sm: '0.75',
        md: '1',
        lg: '1.5',
    }
};
theme.body = {
    font: {
        family: `${theme.fonts.families.primary}`,
        size: `${theme.fonts.sizes.primary}`,
        color: `${theme.colors.text.primary}`,
        weight: `${theme.fonts.weights.normal}`,
        lineHeight: `${theme.fonts.lineHeights.lg}`,
    },
    bg: `${theme.colors.bg.primary}`,
    style: {
        /*custom CSS rules*/
    },
};
theme.input = {
    primary: {
        initial: {
            font: {
                family: `${theme.body.font.family}`,
                color: `${theme.colors.text.quaternary}`,
                size: `${theme.fonts.sizes.xxs}`,
                weight: `${theme.fonts.weights.normal}`,
            },
            bg: {
                color: `${theme.colors.bg.quaternary}`,
                shadow: ``,
            },
            border: {
                isshown: false,
                color: `${theme.colors.bg.quaternary}`,
                style: ``,
                radius: ``,
            },
            style: `
            border-radius: 5px 0 0 5px;
            text-overflow: ellipsis;
            padding: 1.3em 1em;
        `,
        },
        focused: {
            style: `
            outline: none;
        `,
        },
        valid: {},
        invalid: {},
    },
};
theme.button = {
    primary: {
        initial: {
            font: {
                family: `${theme.body.font.family}`,
                color: `${theme.colors.text.tertiary}`,
                size: `${theme.fonts.sizes.xs}`,
                weight: `${theme.fonts.weights.normal}`,
            },
            bg: {
                color: `${theme.colors.text.tertiary}`,
                shadow: ``,
            },
            border: {
                isshown: false,
                color: `${theme.colors.bg.quaternary}`,
                style: ``,
                radius: ``,
            },
            padding: `1em 0`,
            margin: ``,
            style: `
            border-radius: 20px;
        `,
        },
        focused: {
            style: ``,
        },
        hovered: {},
        active: {},
        disabled: {},
    },
    secondary: {
        initial: {
            font: {
                family: `${theme.body.font.family}`,
                color: `${theme.colors.text.tertiary}`,
                size: `${theme.fonts.sizes.xs}`,
                weight: `${theme.fonts.weights.normal}`,
            },
            bg: {
                color: `${theme.colors.bg.quaternary}`,
                shadow: ``,
            },
            border: {
                isshown: false,
                color: `${theme.colors.bg.quaternary}`,
                style: ``,
                radius: ``,
            },
            padding: ``,
            margin: ``,
            style: ``,
        },
        focused: {
            style: `
            outline: none;
        `,
        },
        hovered: {},
        active: {},
        disabled: {},
    },
};
theme.link = {
    primary: {
        initial: {
            font: {
                family: `${theme.body.font.family}`,
                color: `${theme.colors.text.tertiary}`,
                size: `${theme.fonts.sizes.xs}`,
                weight: `${theme.fonts.weights.normal}`,
            },
            bg: {
                color: `${theme.colors.bg.quaternary}`,
                shadow: ``,
            },
            border: {color: `${theme.colors.bg.quaternary}`,
                style: ``,
                radius: ``,
            },
            style: ``,
        },
        hovered: {
            style: ``,
        },
    },
};

export default theme;