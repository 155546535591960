import React, {Component, Fragment} from 'react';
import styled from 'styled-components';

import QR from '../../atoms/qr';

import {NotIPhone5, IPhone5} from '../../../global_styles/responsive_utilities/breakpoins';


import {SPINNER, SUCCESS} from "../../atoms/svg";

import {connect} from 'react-redux';
import {mapStateToProps} from '../../modals/order/container';
import PropTypes from 'prop-types';
import {CRYPTO_CURRENCIES, EMPTY_CURRENCY, NOT_CRYPTO_CURRENCIES} from "../../../environment/supported_currencies";
import TimeExpiredNoteText from "../timeExpiredNoteText";

const ImagesWrapper = styled.div`
  height: 150px;
  @media screen and (max-width: 320px) and (orientation: portrait) {
    height: 120px;
  }
  @media screen and (max-width: 1023px) and (orientation: landscape) {
    margin: 1.5vw 0 0;
  }
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageWrapperBottom = styled.div`
`;

const ImageWrapperTop = styled.div`
  position: absolute;
`;



class ImagesContainer extends Component {
  render() {
    const {order, onlyQR, QRandSpinner, success, expired, template} = this.props;
    return (
      <Fragment>
        {onlyQR && (
          <ImagesWrapper>
            <ImageWrapperBottom>
              <QR z="0" value={order.address} />
            </ImageWrapperBottom>
          </ImagesWrapper>
        )}
        {QRandSpinner && (
          <ImagesWrapper>
            <ImageWrapperBottom>
              <QR blured="true" z="0" value={order.address} />
            </ImageWrapperBottom>
            <ImageWrapperTop>
              <SPINNER/>
            </ImageWrapperTop>
          </ImagesWrapper>
        )}
        {success && (
          <ImagesWrapper>
            <IPhone5>
              <SUCCESS template={template} size="small"/>
            </IPhone5>
            <NotIPhone5>
              <SUCCESS template={template} size="big"/>
            </NotIPhone5>
          </ImagesWrapper>
        )}
        {expired && (
          <TimeExpiredNoteText/>
        )}
      </Fragment>
    );
  }
}

export default connect(mapStateToProps)(ImagesContainer);

ImagesContainer.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    cost: PropTypes.number.isRequired,
    amount: PropTypes.number.isRequired,
    currency: PropTypes.oneOf([EMPTY_CURRENCY, ...NOT_CRYPTO_CURRENCIES, ...CRYPTO_CURRENCIES]).isRequired,
    email: PropTypes.string.isRequired,
    user_id: PropTypes.string.isRequired,
    order_id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    product_name: PropTypes.string.isRequired,
    site_name: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    expired_at: PropTypes.string.isRequired,
    url_return: PropTypes.string.isRequired,
  }).isRequired,
  onlyQR: PropTypes.any,
  QRandSpinner: PropTypes.any,
  success: PropTypes.any,
  expired: PropTypes.any
};
