import React, {Component} from 'react';
import Error from '../organisms/error_body';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  componentDidCatch(error, errorInfo) {
    // this.props.sendErrorMessage({
    //   message: error.message,
    //   componentStack: errorInfo.componentStack,
    // });
  }

  render() {
    const {hasError} = this.state;
    const {children, template} = this.props;
    if (hasError) {
      return <Error template={template} error={'Something go wrong'} />;
    }
    return children;
  }
}

export default ErrorBoundary;
