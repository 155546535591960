import {put, all, takeLatest, call, delay} from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import * as errorTypes from './errorTypes';
import api from '../../services/api';

import handleHttpCode from '../../responseHandling/httpCodeHandling';
import handleResponseBody from '../../responseHandling/responseBodyHandling';
import {innitOrderAjaxSaga} from './actions';

const SECOND = 1000;
const MAX_CALLS_TRIES = 10;

function* checkBodyResponse(
  response,
  current_action,
  action_onSuccess,
  action_onBodyError
) {
  let resultBodyChecking = yield handleResponseBody(response, current_action.type);
  if (resultBodyChecking) {
    yield put(action_onSuccess);
  } else {
    yield put(action_onBodyError);
  }
}

function* handleHttpErrorCode(response, action_onHTTPError) {
  action_onHTTPError.payload.errorInfo.type = yield handleHttpCode(response.status);
  yield put(action_onHTTPError);
}

function* firstLoadOrder(action) {
  let callsCounter = 0;
  let prevError = false;
  while (callsCounter <= MAX_CALLS_TRIES) {
    try {
      const response = yield call(
        api.sendRequest,
        `order/${window.location.pathname.split('/')[1]}`,
        'get'
      );
      yield checkBodyResponse(
        response,
        action,
        {
          type: actionTypes.FIRST_LOAD_ORDER_SUCCESS,
          payload: {
            info: response.data.data,
            theme: response.headers['x-theme']
          },
        },
        {
          type: actionTypes.FIRST_LOAD_ORDER_ERROR,
          payload: {
            error: true,
            errorInfo: {
              type: errorTypes.BODY_ERROR,
              prevAction: action,
              response: response,
            },
          },
        }
      );
      break;
    } catch (error) {
      if(prevError !== false && error !== undefined){
        if (prevError !== error.statusText ) {
          callsCounter = 0;
        }
      }
      prevError = error ? error.statusText : undefined;
      if (callsCounter < MAX_CALLS_TRIES) {
        yield delay(2 * SECOND);
      } else {
        if (error) {
          yield handleHttpErrorCode(error, {
            type: actionTypes.FIRST_LOAD_ORDER_ERROR,
            payload: {
              error: true,
              errorInfo: {
                type: errorTypes.HTTP_CODE_ERROR,
                prevAction: action,
                response: error,
              },
            },
          });
        } else {
          yield put({
            type: actionTypes.FIRST_LOAD_ORDER_ERROR,
            payload: {
              error: true,
              errorInfo: {type: errorTypes.NETWORK_ERROR, prevAction: action},
            },
          });
        }
      }
      callsCounter++;
    }
  }
}

function* loadOrder(action) {
  let callsCounter = 0;
  let prevError = false;
  while (true) {
    try {
      const response = yield call(
        api.sendRequest,
        `order/${window.location.pathname.split('/')[1]}`,
        'get'
      );
      yield checkBodyResponse(
        response,
        action,
        {
          type: actionTypes.LOAD_ORDER_SUCCESS,
          payload: {
            info: response.data.data,
            theme: response.headers['x-theme']
          },
        },
        {
          type: actionTypes.LOAD_ORDER_ERROR,
          payload: {
            error: true,
            errorInfo: {
              type: errorTypes.BODY_ERROR,
              prevAction: action,
              response: response,
            },
          },
        }
      );
      callsCounter = 0;
      yield delay(2 * SECOND);
    } catch (error) {
      if(prevError !== false && error !== undefined){
        if (prevError !== error.statusText ) {
          callsCounter = 0;
        }
      }
     prevError = error ? error.statusText : undefined;
      if (callsCounter <= MAX_CALLS_TRIES) {
        yield delay(2 * SECOND);
      } else {
        if (error) {
          yield handleHttpErrorCode(error, {
            type: actionTypes.LOAD_ORDER_ERROR,
            payload: {
              error: true,
              errorInfo: {
                type: errorTypes.HTTP_CODE_ERROR,
                prevAction: action,
                response: error,
              },
            },
          });
          break;
        } else {
          yield put({
            type: actionTypes.LOAD_ORDER_ERROR,
            payload: {
              error: true,
              errorInfo: {type: errorTypes.NETWORK_ERROR, prevAction: action},
            },
          });
          yield delay(2 * SECOND);
        }
      }
      callsCounter++;
    }
  }
}

function* loadCoins(action) {
  let callsCounter = 0;
  let prevError = false;
  while (callsCounter <= MAX_CALLS_TRIES) {
    try {
      const response = yield call(
        api.sendRequest,
        `order/${window.location.pathname.split('/')[1]}/coins`,
        'get'
      );
      yield checkBodyResponse(
        response,
        action,
        {
          type: actionTypes.LOAD_COINS_SUCCESS,
          payload: {
            list: response.data.data,
            theme: response.headers['x-theme']
          },
        },
        {
          type: actionTypes.LOAD_COINS_ERROR,
          payload: {
            error: false,
            errorInfo: {
              type: errorTypes.BODY_ERROR,
              prevAction: action,
              response: response,
            },
          },
        }
      );
      break;
    } catch (error) {
      if(prevError !== false && error !== undefined){
        if (prevError !== error.statusText ) {
          callsCounter = 0;
        }
      }
      prevError = error ? error.statusText : undefined;
      if (callsCounter < MAX_CALLS_TRIES) {
        yield delay(2 * SECOND);
      } else {
        if (error) {
          yield handleHttpErrorCode(error, {
            type: actionTypes.LOAD_COINS_ERROR,
            payload: {
              error: true,
              errorInfo: {
                type: errorTypes.HTTP_CODE_ERROR,
                prevAction: action,
                response: error,
              },
            },
          });
        } else {
          yield put({
            type: actionTypes.LOAD_COINS_ERROR,
            payload: {
              error: true,
              errorInfo: {type: errorTypes.NETWORK_ERROR, prevAction: action},
            },
          });
        }
      }
      callsCounter++;
    }
  }
}

function* sendCurrency(action) {
  let callsCounter = 0;
  let prevError = false;
  while (callsCounter <= MAX_CALLS_TRIES) {
    try {
      const response = yield call(
        api.sendRequest,
        `/order/${window.location.pathname.split('/')[1]}/${action.payload.currency}`,
        'post',
        action.payload
      );
      if (response.data.data.redirect_url && response.data.data.redirect_url.length !== 0){
        yield put({type:actionTypes.NOT_OPEN_POPUP});
        window.location.replace(response.data.data.redirect_url);
      }
      yield checkBodyResponse(
        response,
        action,
        {
          type: actionTypes.SEND_CURRENCY_SUCCESS,
          payload: {
            info: response.data.data,
            theme: response.headers['x-theme']
          },
        },
        {
          type: actionTypes.SEND_CURRENCY_ERROR,
          payload: {
            error: true,
            errorInfo: {
              type: errorTypes.BODY_ERROR,
              prevAction: action,
              response: response,
            },
          },
        }
      );
      break;
    } catch (error) {
      if(prevError !== false && error !== undefined){
        if (prevError !== error.statusText ) {
          callsCounter = 0;
        }
      }
      prevError = error ? error.statusText : undefined;
      if (callsCounter < MAX_CALLS_TRIES) {
        yield delay(2 * SECOND);
      } else {
        if (error) {
          yield handleHttpErrorCode(error, {
            type: actionTypes.SEND_CURRENCY_ERROR,
            payload: {
              error: true,
              errorInfo: {
                type: errorTypes.HTTP_CODE_ERROR,
                prevAction: action,
                response: error,
              },
            },
          });
        } else {
          yield put({
            type: actionTypes.SEND_CURRENCY_ERROR,
            payload: {
              error: true,
              errorInfo: {type: errorTypes.NETWORK_ERROR, prevAction: action},
            },
          });
        }
      }
      callsCounter++;
    }
  }
}

function* sendStatus(action) {
  let callsCounter = 0;
  let prevError = false;
  while (callsCounter <= MAX_CALLS_TRIES) {
    try {
      let response = yield call(
        api.sendRequest,
        `/order/${window.location.pathname.split('/')[1]}/status/${
          action.payload.status
        }`,
        'post'
      );
      yield checkBodyResponse(
        response,
        action,
        {
          type: actionTypes.SEND_STATUS_SUCCESS,
          payload: {
            info: response.data.data,
            theme: response.headers['x-theme']
          },
        },
        {
          type: actionTypes.SEND_STATUS_ERROR,
          payload: {
            error: true,
            errorInfo: {
              type: errorTypes.BODY_ERROR,
              prevAction: action,
              response: response,
            },
          },
        }
      );
      break;
    } catch (error) {
      if(prevError !== false && error !== undefined){
        if (prevError !== error.statusText ) {
          callsCounter = 0;
        }
      }
      prevError = error ? error.statusText : undefined;
      if (callsCounter < MAX_CALLS_TRIES) {
        yield delay(2 * SECOND);
      } else {
        if (error) {
          yield handleHttpErrorCode(error, {
            type: actionTypes.SEND_STATUS_ERROR,
            payload: {
              error: true,
              errorInfo: {
                type: errorTypes.HTTP_CODE_ERROR,
                prevAction: action,
                response: error,
              },
            },
          });
        } else {
          yield put({
            type: actionTypes.SEND_STATUS_ERROR,
            payload: {
              error: true,
              errorInfo: {type: errorTypes.NETWORK_ERROR, prevAction: action},
            },
          });
        }
      }
      callsCounter++;
    }
  }
}

export default function* actionsListener() {
  yield put(innitOrderAjaxSaga());
  yield all([
    takeLatest(actionTypes.LOAD_ORDER, loadOrder),
    takeLatest(actionTypes.SEND_CURRENCY, sendCurrency),
    takeLatest(actionTypes.LOAD_COINS, loadCoins),
    takeLatest(actionTypes.SEND_STATUS, sendStatus),
    takeLatest(actionTypes.FIRST_LOAD_ORDER, firstLoadOrder),
  ]);
}
