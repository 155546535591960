import React, {Component, Fragment} from 'react';
import styled from 'styled-components';

import CoinsMenu from '../../molecules/coinsMenu';
import NavBar from '../../molecules/navigationBar';
import Info from '../../molecules/info';

import {connect} from 'react-redux';
import {mapStateToProps} from './container';
import PropTypes from 'prop-types';
import {CRYPTO_CURRENCIES, EMPTY_CURRENCY, NOT_CRYPTO_CURRENCIES} from "../../../environment/supported_currencies";

const StyledWrapper = styled.div`
    padding: 0 7vw;
    @media screen and (max-width: 1023px) and (orientation:landscape) {
         padding: 0 10vh;
    }
`;

const StyledInfoWrapper = styled.div`
    padding: 1.5rem 0; 
    background-color: ${props => props.template === 'fc' ? '#F7F7FA' : props.theme.colors.bg.secondary}; `;

const StyledNavBarWrapper = styled.div`
  margin: 2vh 0;
  @media screen and (max-width: 1023px) and (orientation: landscape) {
    margin: 2vw 0;
  }
`;

class Body extends Component {
  render() {
    const {order, currencyLoading, template} = this.props;
    return (
      <Fragment>
        {!((order.status === 'pending' && CRYPTO_CURRENCIES.includes(order.currency)) ||
                order.status === 'processing' ||
                order.status === 'successful' ||
                order.status === 'failed' ||
                order.status === 'expired' ||
                order.status === 'canceled') &&
            <StyledInfoWrapper template={template}>
              <Info template={template} />
            </StyledInfoWrapper>
        }
        <StyledWrapper>
          <StyledNavBarWrapper>
            {(order.status === 'pending' && order.currency === 'USD') && !currencyLoading && <NavBar />}
          </StyledNavBarWrapper>
          <CoinsMenu template={template} />
        </StyledWrapper>
      </Fragment>
    );
  }
}

export default connect(mapStateToProps)(Body);

Body.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    cost: PropTypes.number.isRequired,
    amount: PropTypes.number.isRequired,
    currency: PropTypes.oneOf([EMPTY_CURRENCY, ...NOT_CRYPTO_CURRENCIES, ...CRYPTO_CURRENCIES]).isRequired,
    email: PropTypes.string.isRequired,
    user_id: PropTypes.string.isRequired,
    order_id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    product_name: PropTypes.string.isRequired,
    site_name: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    expired_at: PropTypes.string.isRequired,
    url_return: PropTypes.string.isRequired,
  }).isRequired,
  onlyQR: PropTypes.any,
  QRandSpinner: PropTypes.any,
  success: PropTypes.any,
};
