import {sendStatus, setTimeLeft, sendCurrency} from '../../../logic/order/actions';

export function mapStateToProps(state) {
  const {order} = state;
  return {
    order: order.info,
    firstLoading: order.firstLoading,
    timeLeft: order.timeLeft,
    orderError: order.error,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    sendStatus(status) {
      dispatch(sendStatus(status));
    },
    sendCurrency(currency){
      dispatch(sendCurrency(currency))
    },
    setTimeLeft(timeleft) {
      dispatch(setTimeLeft(timeleft));
    },
  };
}
