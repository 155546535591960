import defaultState from '../defaultState';
import * as actionTypes from './actionTypes';

export function coinsReducer(state = defaultState.coins, action) {
  switch (action.type) {
    case actionTypes.LOAD_COINS: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionTypes.LOAD_COINS_SUCCESS: {
      return {
        ...state,
        list: action.payload.list,
        loading: false,
        error: false,
      };
    }
    case actionTypes.LOAD_COINS_ERROR: {
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}

export function orderReducer(state = defaultState.order, action) {
  switch (action.type) {
    case 'SOCKET_CONNECT': {
      return {
        info:state.info,
        timeLeft: state.timeLeft,
        currencyLoading: state.currencyLoading,
        error: state.error,
        errorInfo: state.errorInfo,
        firstLoading: state.firstLoading,
        //Todo: webSocket
        //ws: action.ws,
      };
    }
    case actionTypes.FIRST_LOAD_ORDER: {
      return {
        ...state,
        firstLoading: true
      };
    }
    case actionTypes.FIRST_LOAD_ORDER_SUCCESS: {
      return {
        ...state,
        info: action.payload.info,
        error: false,
        firstLoading: false,
        theme: action.payload.theme
      };
    }
    case actionTypes.FIRST_LOAD_ORDER_ERROR: {
      return {
        ...state,
        ...action.payload,
        firstLoading: false
      };
    }
    case actionTypes.LOAD_ORDER: {
      return {
        ...state,
        loading: true
      };
    }
    case actionTypes.LOAD_ORDER_SUCCESS: {
      return {
        ...state,
        info: action.payload.info,
        loading: false,
        error: false,
        theme: action.payload.theme
      };
    }
    case actionTypes.LOAD_ORDER_ERROR: {
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    }
    case actionTypes.SET_TIME_LEFT: {
      return {
        ...state,
        timeLeft: action.payload.timeLeft
      };
    }
    case actionTypes.SEND_CURRENCY: {
      return {
        ...state,
        currencyLoading: true
      };
    }
    case actionTypes.SEND_CURRENCY_SUCCESS: {
      return {
        ...state,
        info: action.payload.info,
        currencyLoading: false,
        error: false,
        theme: action.payload.theme
      };
    }
    case actionTypes.SEND_CURRENCY_ERROR: {
      return {
        ...state,
        ...action.payload,
        currencyLoading: false
      };
    }
    case actionTypes.SEND_STATUS: {
      return {
        ...state,
        loading: true
      };
    }
    case actionTypes.SEND_STATUS_SUCCESS: {
      return {
        ...state,
        info: action.payload.info,
        loading: false,
        error: false,
        theme: action.payload.theme
      };
    }
    case actionTypes.SEND_STATUS_ERROR: {
      return {
        ...state,
        ...action.payload,
        loading: false
      };
    }
    case actionTypes.NOT_OPEN_POPUP:{
      return {
        ...state,
        shouldOpenPopup: false
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
