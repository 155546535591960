import React, {Fragment} from 'react';
import styled from 'styled-components';

import {IPhone5,NotIPhone5} from '../../../global_styles/responsive_utilities/breakpoins';

import PropTypes from 'prop-types';

var QRCode = require('qrcode.react');

const StyledQRCode = styled(QRCode)`
  z-index: ${props => props.z};
  opacity: ${props =>
    props.blured ? '0.05' : props.hidden ? '0' : props.disabled ? '0.5' : '1'};
`;

const QR = props => {
  return (
    <Fragment>
      <NotIPhone5>
        <StyledQRCode
          value={props.value}
          size={150}
          z={props.z}
          blured={props.blured}
          renderAs={'svg'}
        />
      </NotIPhone5>
      <IPhone5>
        <StyledQRCode
          value={props.value}
          size={120}
          z={props.z}
          blured={props.blured}
          renderAs={'svg'}
        />
      </IPhone5>
    </Fragment>
  );
};

export default QR;

QR.propTypes = {
  value: PropTypes.string,
  z: PropTypes.string,
  blured: PropTypes.oneOf(['true', undefined]),
  hidden: PropTypes.oneOf(['true', undefined]),
  disabled: PropTypes.oneOf(['true', undefined]),
};
