import React from 'react';
import MediaQuery from 'react-responsive';
import PropTypes from 'prop-types';


export  function IPhone5(props) {
  return <Breakpoins name="iPhone5">{props.children}</Breakpoins>;
}


export  function NotIPhone5(props) {
  return <Breakpoins name="not_iPhone5">{props.children}</Breakpoins>;
}

export  function Mobile(props) {
  return <Breakpoins name="mobile">{props.children}</Breakpoins>;
}

export  function MobileLandscape(props) {
  return <Breakpoins name="mobile_l">{props.children}</Breakpoins>;
}


export  function Tablet(props) {
  return <Breakpoins name="tablet">{props.children}</Breakpoins>;
}

export  function TabletLandscape(props) {
  return <Breakpoins name="tablet_l">{props.children}</Breakpoins>;
}

export function Desktop(props) {
  return <Breakpoins name="desktop">{props.children}</Breakpoins>;
}

export function LargeDesktop(props) {
  return <Breakpoins name="largeDesktop">{props.children}</Breakpoins>;
}



class Breakpoins extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      iPhone5:
          '(max-width: 320px) and (orientation: portrait)',
      not_iPhone5:
          '(min-width: 321px)',
      mobile:
          '(max-width: 599px) and (orientation: portrait)',
      mobile_l:
          '(max-width: 899px) and (orientation: landscape)',
      tablet:
          '(min-width:600px) and (orientation: portrait)',
      tablet_l:
          '(min-width: 900px) and (orientation: landscape)',
      desktop:
          '(min-width: 1200px)',
      largeDesktop:
          '(min-width: 1800px)',

    };
  }

  render() {
    const breakpoint = this.state[this.props.name] || this.state.desktop;
    return (
      <MediaQuery {...this.props} query={breakpoint}>
        {this.props.children}
      </MediaQuery>
    );
  }
}

Breakpoins.propTypes = {
  name: PropTypes.string.isRequired,
};
