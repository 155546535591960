import React, {Component} from 'react';
import styled from 'styled-components';
import Text from '../../atoms/text';

import PropTypes from 'prop-types';
const StyledText = styled(Text)`
  font-size: ${props => props.fontSize ? props.fontSize : props.theme.fonts.sizes.xl}
`;

export default class TitleText extends Component {
  render() {
    const {children} = this.props;
    return (
      <StyledText
        grey="true"
        light="true"
        fontSize = {this.props.fontSize}
        template={this.props.template}>
        {children}
      </StyledText>
    );
  }
}

TitleText.propType = {
  children: PropTypes.string,
};
