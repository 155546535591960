import axios from 'axios';
import config from '../config';

const SECOND = 1000;

class Api {
  constructor(url) {
    const instance = axios.create({
      baseURL: url,
      responseType: 'json',
      timeout: 20 * SECOND,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'X-Requested-With, Content-Type',
      },
    });
    instance.interceptors.response.use(
      response => {
        return response;
      },
      function(error) {
        if (error) return Promise.reject(error.response);
      }
    );
    this.adapter = instance;
  }

  sendRequest = (url, type, payload) => {
    return this.adapter.request({
      url: url,
      method: type,
      data: payload,
    });
  };
}

export default new Api(config.apiHost);
