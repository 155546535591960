import * as errorTypes from '../logic/order/errorTypes';

const STATUS_200 = 200;
const STATUS_201 = 201;
const STATUS_401 = 401;
const STATUS_404 = 404;
const STATUS_422 = 422;
const STATUS_500 = 500;

export default function httpCodeHandling(status) {
  switch (status) {
    case STATUS_200: {
      return 'OK';
    }
    case STATUS_201: {
      return 'OK';
    }
    case STATUS_401: {
      return errorTypes.AUTHORIZATION_ERROR;
    }
    case STATUS_404: {
      return errorTypes.NOT_FOUND_ERROR;
    }
    case STATUS_422: {
      return errorTypes.VALIDATION_ERROR;
    }
    case STATUS_500: {
      return errorTypes.SERVER_ERROR;
    }
    default: {
      return errorTypes.HTTP_CODE_ERROR;
    }
  }
}
