import {createGlobalStyle} from 'styled-components';
import normalize from './normalize';

export default createGlobalStyle`
  ${normalize}
html,
body,
#root{
  height: 100%;
  margin: 0;
  padding: 0;
}
#root{
     font-family: 'Open Sans', sans-serif;
}

html{
  font-size: 100% !important;
  font-family: 'Open Sans', sans-serif;
}
   @media screen and (max-width: 1366px) and (orientation:portrait) {
            html, #root  {font-size: 125% !important;}
        }   
   @media screen and (min-width: 769px) and (max-width: 1023px) and  (orientation:portrait) {
            html, #root  {font-size: 120% !important;}
   } 
   @media screen and (max-width: 768px) and (orientation:portrait) {
            html, #root  {font-size: 115% !important;}
        }
   @media screen and (max-width: 414px) and (orientation:portrait) {
            html, #root  {font-size: 95% !important;}
        }
   @media screen and (max-width: 320px) and (orientation:portrait) {
            html, #root {font-size: 85% !important;}
        }
`;
