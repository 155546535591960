import * as actionTypes from './actionTypes';

export function loadCoins() {
  return {
    type: actionTypes.LOAD_COINS,
  };
}

export function loadOrder() {
  return {
    type: actionTypes.LOAD_ORDER,
  };
}

export function firstLoadOrder() {
  return {
    type: actionTypes.FIRST_LOAD_ORDER,
  };
}

export function setTimeLeft(timeLeft) {
  return {
    type: actionTypes.SET_TIME_LEFT,
    payload: {timeLeft},
  };
}

export function sendCurrency(currency) {
  return {
    type: actionTypes.SEND_CURRENCY,
    payload: {currency},
  };
}

export function sendStatus(status) {
  return {
    type: actionTypes.SEND_STATUS,
    payload: {status},
  };
}

export function socketOpen(e) {
  return {
    type: 'SOCKET_OPEN',
  };
}

export function socketClose(e) {
  return {
    type: 'SOCKET_CLOSE',
  };
}

export function socketError(err) {
  return {
    type: 'SOCKET_ERROR',
    payload: err,
  };
}

export function socketMessage(e) {
  return {
    type: 'SOCKET_MESSAGE',
    payload: JSON.parse(e.data),
  };
}

export function socketConnect(e) {
  return {
    type: 'SOCKET_CONNECT',
  };
}

export function innitWsSaga() {
  return {
    type: actionTypes.INNIT_WS_SAGA,
  };
}

export function innitOrderAjaxSaga() {
  return {
    type: actionTypes.INNIT_ORDER_AJAX_SAGA,
  };
}
