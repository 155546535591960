import React, {Component} from 'react';

export default class CustomLoader extends Component {
  render() {
    return (
        <div id="uloader">
            <div className="uloader-primary"></div>
            <div className="uloader-secondary"></div>
        </div>
    );
  }
}
