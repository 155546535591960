import React, {Component} from 'react';
import styled from 'styled-components';

import Text from '../../atoms/text';

const StyledText = styled(Text)`
  margin-right: 1rem;
  @media (max-width: 899px) {
    margin-right: 0.5rem;
  }
  color: ${props => props.template === 'fc' ? '#6B5F86' : props.theme.colors.text.secondary};
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`

export default class RightsText extends Component {
  render() {
    return (
      <Wrapper>
        <StyledText bold="true" lineHeight="1rem">{new Date().getFullYear()}</StyledText>
        <StyledText bold="true" fontSize="1.3rem" lineHeight="1rem" >©</StyledText>
        <StyledText bold="true" lineHeight="1rem">Centrobill</StyledText>
      </Wrapper>
    );
  }
}
