import React, {Component, Fragment} from 'react';
import styled from 'styled-components';

import ModalHeader from '../../organisms/modal_header';
import ModalFooter from '../../organisms/modal_footer';
import ModalBody from '../../organisms/modal_body';
import FailedSubModal from './subModals/failed';

import {connect} from 'react-redux';
import {mapStateToProps, mapDispatchToProps} from './container';
import PropTypes from 'prop-types';
import {CRYPTO_CURRENCIES, EMPTY_CURRENCY, NOT_CRYPTO_CURRENCIES} from "../../../environment/supported_currencies";
import OrderProcessingNoteText from "../../molecules/orderProcessingNoteText";

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center; 
  gap: 2rem;
  padding: 2rem;
`;

class ModalContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTime: Math.floor(Date.now()/1000),
    };
  }

  componentDidMount() {
    const {order} = this.props;
    if (order.status !== 'failed' && order.status !== 'canceled' && order.status !== 'expired' && order.status !== 'successful') {
      this.setOrderTimeLeft();
    }
    this.currentTimer = setInterval(() => {
      this.setState({
        currentTime: Math.floor(Date.now()/1000),
      });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.currentTimer);
  }

  countOrderTimeLeft() {
    const {order} = this.props;
    let millis = Date.now();
    let secs = Math.floor(millis/1000);
    return order.timestamp - secs;
  }

  setOrderTimeLeft() {
    const {setTimeLeft} = this.props;
    let currentTimeLeft = this.countOrderTimeLeft();
    setTimeLeft(currentTimeLeft);
  }

  render() {
    const {order, closeModal, template} = this.props;
    const { currentTime } = this.state;
    return (
      <StyledWrapper>
        {order.status !== 'failed' && order.status !== 'canceled' && (
            <Fragment>
              <ModalHeader template={template}/>
              <ModalBody template={template} closeModal={closeModal} currentTime={currentTime} />
              {(order.status === 'pending' || order.status === 'processing' || order.status === 'expired') &&
                <OrderProcessingNoteText/>
              }
              <ModalFooter template={template}/>
            </Fragment>
        )}
        {(order.status === 'failed' || order.status === 'canceled') && (
            <FailedSubModal status={order.status}/>
        )}
      </StyledWrapper>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalContent);

ModalContent.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    cost: PropTypes.number.isRequired,
    amount: PropTypes.number.isRequired,
    currency: PropTypes.oneOf([EMPTY_CURRENCY, ...NOT_CRYPTO_CURRENCIES, ...CRYPTO_CURRENCIES]).isRequired,
    email: PropTypes.string.isRequired,
    user_id: PropTypes.string.isRequired,
    order_id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    product_name: PropTypes.string.isRequired,
    site_name: PropTypes.string.isRequired,
    created_at: PropTypes.string.isRequired,
    expired_at: PropTypes.string.isRequired,
    url_return: PropTypes.string.isRequired,
  }).isRequired,
  closeModal: PropTypes.func,
  sendStatus: PropTypes.func,
};
