import {sendCurrency} from '../../../logic/order/actions';

export function mapStateToProps(state) {
  const {order, coins} = state;
  return {
    coins: coins.list,
    order: order.info,
    currencyLoading: order.currencyLoading,
    coinsLoading: coins.loading,
    shouldOpenPopup: order.shouldOpenPopup
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    sendCurrency(currency) {
      dispatch(sendCurrency(currency));
    },
  };
}
