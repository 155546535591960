import React, {Component} from 'react';
import styled from 'styled-components';

import Text from '../../atoms/text';
import {ALink} from '../../atoms/link';

import {connect} from 'react-redux';
import {mapStateToProps} from './container';
import PropTypes from 'prop-types';
import {CRYPTO_CURRENCIES, EMPTY_CURRENCY, NOT_CRYPTO_CURRENCIES} from "../../../environment/supported_currencies";

const StyledText = styled(Text)`
  margin-bottom: ${props => props.modal ? parseInt(props.marginBottom, 10) + 3 + 'px' : props.marginBottom};
`;

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  ${StyledText}:nth-child(1) {
       font-size: ${props => props.theme.fonts.sizes.sm}
  }
  ${StyledText}:nth-child(2) {
       font-size: ${props => props.theme.fonts.sizes.xxs}
  }
  ${StyledText}:nth-child(3) {
       font-size: ${props => props.theme.fonts.sizes.lg}
  }
`;



class Info extends Component {
  render() {
    const {modal, order} = this.props;
    let currency = order['currency'] === EMPTY_CURRENCY ? 'USD' : order['currency'];
    return (
      <StyledWrapper>
        <StyledText
          template={this.props.template}
          medium="true"
          grey="true"
          modal={modal}
          marginBottom="0px">
          {order['product_name']}
        </StyledText>
        <StyledText
          template={this.props.template}
          medium="true"
          grey="true"
          modal={modal}
          marginBottom="5px">
          at{' '}
          <ALink href={'http://' + order['site_name']} target="_blank" rel="noopener noreferrer" underline="true">
            {order['site_name']}
          </ALink>
        </StyledText>

        {!modal &&
            <StyledText
          template={this.props.template}
          bold="true"
          grey="true">
          {order['cost'] + ' ' + currency}
        </StyledText>
        }
      </StyledWrapper>
    );
  }
}

export default connect(mapStateToProps)(Info);

Info.propTypes = {
  modal: PropTypes.any,
  order: PropTypes.shape({
    id: PropTypes.string,
    address: PropTypes.string,
    cost: PropTypes.number,
    amount: PropTypes.number,
    currency: PropTypes.oneOf([EMPTY_CURRENCY, ...NOT_CRYPTO_CURRENCIES, ...CRYPTO_CURRENCIES]).isRequired,
    email: PropTypes.string,
    user_id: PropTypes.string,
    order_id: PropTypes.string,
    status: PropTypes.string,
    product_name: PropTypes.string,
    site_name: PropTypes.string,
    created_at: PropTypes.string,
    expired_at: PropTypes.string,
    url_return: PropTypes.string.isRequired,
  }),
  onlyQR: PropTypes.any,
  QRandSpinner: PropTypes.any,
  success: PropTypes.any,
};
