import createSagaMiddleware from 'redux-saga';
import ajaxSaga from './logic/ajaxSaga';
//Todo: webSocket

//import wsSaga from './logic/wsSaga';
import reducer from './logic/reducer';
import {createStore, applyMiddleware} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';

const ajaxMiddleware = createSagaMiddleware();
//Todo: webSocket
//const websocketMiddleware = createSagaMiddleware();
//const middlewares = [ajaxMiddleware, websocketMiddleware];
const middlewares = [ajaxMiddleware];
const store = createStore(reducer, composeWithDevTools(applyMiddleware(...middlewares)));
ajaxMiddleware.run(ajaxSaga);

//Todo: webSocket
//websocketMiddleware.run(wsSaga);

export default store;
