import {NavLink} from 'react-router-dom';
import styled from 'styled-components';

import PropTypes from 'prop-types';

export const Link = styled(NavLink)`
  text-decoration: ${props => (props.underline ? 'underline' : 'none')};
  font-family: inherit;
  font-size: inherit;
  color: ${props => props.template === 'fc' ? "#3F3456" : "inherit"};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  &&:hover {
    color: ${props => (props.filledBtn ? props.theme.body.bg : props.theme.colors.text.tertiary)};
  }
  pointer-events: ${props => (props.disabled ? 'none' : '')};
  opacity: ${props => (props.disabled ? '0.5' : '')};
`;

export const ALink = styled.a`
  text-decoration: ${props => (props.underline ? 'underline' : 'none')};
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  :hover {
    color: ${props => (props.filledBtn ? props.theme.body.bg : props.theme.colors.text.tertiary)};
  }
  pointer-events: ${props => (props.disabled ? 'none' : '')};
  opacity: ${props => (props.disabled ? '0.5' : '')};
`;

Link.propTypes = {
  underline: PropTypes.oneOf(['true', undefined]),
  disabled: PropTypes.oneOf(['true', undefined]),
  filledBtn: PropTypes.oneOf(['true', undefined]),
};
