import React, {Component} from 'react';
import styled from 'styled-components';

import Text from '../../atoms/text';

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
`;

export default class OrderProcessingNoteText extends Component {
    render() {
        return (
            <StyledWrapper>
                <Text whiteSpace="normal" grey="true" fontSize="0.7rem">
                    Do not close this window.
                </Text>
                <Text whiteSpace="normal" grey="true" fontSize="0.7rem">
                    After completion of your payment, it might take several minutes until confirmation
                </Text>
            </StyledWrapper>
        );
    }
}

