import React, {Component} from 'react';

import Text from '../../atoms/text';

import {connect} from 'react-redux';
import {mapStateToProps, mapDispatchToProps} from './container';
import PropTypes from 'prop-types';
import {setTimeLeft} from "../../../logic/order/actions";

const SECOND = 1000;

class Timer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hours: '00',
      seconds: '00',
      minutes: '00',
    };
  }

  getTime = seconds => {
    return {
      hour: this.getHours(seconds),
      min: this.getMinutes(seconds),
      sec: this.getSeconds(seconds),
    };
  };

  tick = () => {
    const {order} = this.props;
    let time = this.getTime(this.secondsRemaining);

    if (time.sec >= 0) {
      this.setState({
        minutes: time.min,
        seconds: time.sec,
        hours: time.hour,
      });
    }

    if (time.sec < 10 && time.sec >= 0) {
      this.setState({
        seconds: '0' + this.state.seconds,
      });
    }

    if (time.min < 10 && time.sec >= 0) {
      this.setState({
        minutes: '0' + this.state.minutes,
      });
    }
    if (time.hour < 10 && time.sec >= 0) {
      this.setState({
        hours: '0' + this.state.hours,
      });
    }

    if ((time.min === 0) & (time.sec === -1) & (time.hour === 0)) {
      clearInterval(this.intervalHandle);
      if(order.status === 'pending' || order.status === 'processing'){
        setTimeLeft(-1);
      }
    }

    this.secondsRemaining--;
  };

  getHours = seconds => {
    return Math.trunc(seconds / 60 / 60);
  };
  getMinutes = seconds => {
    let hours = this.getHours(seconds);
    return Math.trunc((this.secondsRemaining - 60 * 60 * hours) / 60);
  };
  getSeconds = seconds => {
    let hours = this.getHours(seconds),
      minutes = this.getMinutes(seconds);
    return seconds - hours * 60 * 60 - minutes * 60;
  };

  componentDidMount() {
    const {timeLeft} = this.props;
    this.intervalHandle = setInterval(this.tick, SECOND);
    this.secondsRemaining = timeLeft - 1;
    let time = this.getTime(timeLeft);
    time.hour < 10
      ? this.setState({hours: '0' + time.hour})
      : this.setState({hours: time.hour});
    time.min < 10
      ? this.setState({minutes: '0' + time.min})
      : this.setState({minutes: time.min});
    time.sec < 10
      ? this.setState({seconds: '0' + time.sec})
      : this.setState({seconds: time.sec});
  }

  componentWillUnmount() {
    clearInterval(this.intervalHandle);
  }

  render() {
    const {hours, minutes, seconds} = this.state;
    return (
      <div>
        <Text lightGrey="true" medium="true" fontSize="1.38rem">
          {hours}:{minutes}:{seconds}
        </Text>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Timer);

Timer.propTypes = {
  setTimeLeft: PropTypes.func,
  timeLeft: PropTypes.number,
  closeModal: PropTypes.func,
};
