import defaultState from '../defaultState';
import * as actionTypes from './actionTypes';

export function serviceReducer(state = defaultState.serviceMessage, action) {
  switch (action.type) {
    case actionTypes.SEND_ERROR_MESSAGE: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionTypes.SEND_ERROR_MESSAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: false,
      };
    }
    case actionTypes.SEND_ERROR_MESSAGE_ERROR: {
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    }
    default: {
      return {
        ...state,
      };
    }
  }
}
