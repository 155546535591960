import * as actionTypes from './actionTypes';

export function sendErrorMessage(error) {
  return {
    type: actionTypes.SEND_ERROR_MESSAGE,
    payload: error,
  };
}

export function innitServiceAjaxSaga() {
  return {
    type: actionTypes.INNIT_SERVICE_AJAX_SAGA,
  };
}
