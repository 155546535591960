import {loadCoins, loadOrder, firstLoadOrder} from '../../../logic/order/actions';
import {sendErrorMessage} from '../../../logic/serviceMessage/actions';

export function mapStateToProps(state) {
  const {order, coins} = state;
  return {
    order: order.info,
    firstLoading: order.firstLoading,
    error: order.error,
    //Todo: webSocket
    //ws: order.ws,
    orderError: order.error,
    coinsError: coins.error,
    orderErrorInfo: order.errorInfo,
    coinsErrorInfo: coins.errorInfo,
  };
}

export function mapDispatchToProps(dispatch) {
  return {
    firstLoadOrder() {
      dispatch(firstLoadOrder());
    },
    loadCoins() {
      dispatch(loadCoins());
    },
    loadOrder() {
      dispatch(loadOrder());
    },
    //Todo: webSocket

    // innitWS() {
    //   dispatch({type: 'INNIT_WS_SAGA'});
    // },
    sendErrorMessage(error) {
      dispatch(sendErrorMessage(error));
    },
  };
}
