import {combineReducers} from 'redux';

import {coinsReducer, orderReducer} from './order/reducer';
import {serviceReducer} from './serviceMessage/reducer';

export default combineReducers({
  coins: coinsReducer,
  order: orderReducer,
  service: serviceReducer,
});
