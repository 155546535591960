import React, {Fragment} from 'react';
import { useLocation } from "react-router-dom"
import styled, {ThemeProvider} from 'styled-components';
import Header from '../organisms/header';
import Footer from '../organisms/footer';

import {Switch, Route, withRouter} from 'react-router-dom';

import SupportPage from './support';
import TermsPage from './terms';
import PrivacyPage from './privacy';
import CoinChoicePage from './coinChoice';
import getTheme from "../../global_styles/themes";

import {connect} from 'react-redux';
import {mapStateToProps} from './container';


const StyledWrapper = styled.div`
  min-height: 100%;
`;
const StyledContentWrapper = styled.div`
  padding-bottom: 4em;
  .dialog-enter {
    opacity: 0.01;
  }
`;

const StyledFooterWrapper = styled.div`
  height: 4em;
  margin-top: -4em;
  background-color: ${props => props.template === 'fc' ? "#3F3456" : "#f6f6f6"};
  overflow: hidden;
`;


function Page(props) {
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const tpl = params.get("m")

    return (
      <ThemeProvider theme={getTheme(props.theme)}>
        <Fragment>
          <StyledWrapper>
            <StyledContentWrapper>
              <Header template={tpl} />
              <Switch>
                <Route exact path="/" title={""} component={SupportPage} />
                <Route path="/support/" title={"/support"} component={SupportPage} />
                <Route path="/terms/" title={"/terms"} component={TermsPage} />
                <Route path="/privacy/" title={"/privacy"} component={PrivacyPage} />
                  <Route path="/:id" title={"/order"}><CoinChoicePage template={tpl}/></Route>
              </Switch>
            </StyledContentWrapper>
          </StyledWrapper>
          <StyledFooterWrapper>
            <Footer template={tpl} />
          </StyledFooterWrapper>
        </Fragment>
      </ThemeProvider>
  );
}

export default connect(mapStateToProps)(withRouter(Page));
