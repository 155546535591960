import React, {Component, Fragment} from 'react';
import styled from 'styled-components';

import Button from '../../atoms/button';
import {ALink} from '../../atoms/link';

import PropTypes from 'prop-types';

const StyledButton = styled(Button)`
  align-self: center;
  margin:0!important;
`;

export default class PlainButton extends Component {
  render() {
    const {
      notFilled_withoutLink,
      notFilled_withLink,
      notFilled_withLink_samePage,
      filled_withoutLink,
      handleClick,
      children,
      href,
      disabled,
      template
    } = this.props;
    return (
      <Fragment>
        {notFilled_withoutLink && (
          <StyledButton template={template} size="small" onClick={handleClick} disabled={disabled}>
            {children}
          </StyledButton>
        )}
        {notFilled_withLink && (

            <ALink template={template} filledBtn="true" href={href} target="_blank" disabled={disabled}>
              <StyledButton template={template} size="small" filled="true">
              {children}
              </StyledButton>
            </ALink>

        )}
        {notFilled_withLink_samePage && (

            <ALink template={template} filledBtn="true" href={href} disabled={disabled}>
              <StyledButton template={template} size="small" filled="true">
                {children}
              </StyledButton>
            </ALink>

        )}
        {filled_withoutLink && (
          <StyledButton template={template} filled="true" onClick={handleClick} disabled={disabled}>
            {children}
          </StyledButton>
        )}
      </Fragment>
    );
  }
}

PlainButton.propTypes = {
  notFilled_withoutLink: PropTypes.any,
  notFilled_withLink: PropTypes.any,
  notFilled_withLink_samePage: PropTypes.any,
  filled_withoutLink: PropTypes.any,
  handleClick: PropTypes.func,
  children: PropTypes.string,
  href: PropTypes.string,
};
